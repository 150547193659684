import React from 'react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Icon from 'react-feather'

import styles from './RangeCalendar.module.scss';

const RangeCalendar = ({ onSetRanges, minDate, dateRange, showTextLabels }) => {

    return (
        <div className={styles.pickerWrapper}>
            {showTextLabels && <span className='u-m-right-1'>From:</span>}
            <Icon.Calendar size="26" />
            <DatePicker
                dateFormat='dd/MM/yyyy'
                wrapperClassName={styles.picker}
                className="picker"
                selected={dateRange.startDate && dateRange.startDate}
                selectsStart
                startDate={dateRange.startDate && dateRange.startDate}
                minDate={minDate}
                placeholderText='Start date'
                maxDate={moment().toDate()}
                endDate={dateRange.endDate}
                onChange={(date) => onSetRanges({ ...dateRange, startDate: date })}
            />
            {showTextLabels && <span className='u-m-right-1'>To:</span>}

            <DatePicker
                dateFormat='dd/MM/yyyy'
                wrapperClassName={styles.picker}
                className=" picker"
                selected={dateRange.endDate && dateRange.endDate}
                placeholderText='End date'
                selectsEnd
                maxDate={moment().toDate()}
                startDate={dateRange.startDate}
                minDate={dateRange.startDate}
                endDate={dateRange.endDate}
                onChange={(date) => onSetRanges({ ...dateRange, endDate: date })}
            />
        </div>
    )
}
export default RangeCalendar;