import React, { useEffect, useState, useRef } from 'react';
import { ReactComponent as BandIcon } from '../../../images/icons/band.svg';
import TruncateLog from '../../../components/TruncateLog';
import { useIntersection } from 'react-use';
import * as Icon from 'react-feather';
import { ACTIVITY_TYPES } from '../../../utils/constants';
import { BOOK_TYPES } from '../../../components/BookSearch/services/constants'
import moment from 'moment';
import { MARK_LOG_AS_LIKED, MARK_LOG_AS_SEEN, MARK_REVIEW_AS_LIKED, MARK_REVIEW_AS_SEEN } from '../services/graphql';
import { useMutation } from '@apollo/react-hooks';
import styles from './Search.module.scss';
import cx from 'classnames';
import { Tooltip } from 'react-tippy';
import ls from 'local-storage';

const stars = [1, 2, 3, 4, 5]

const ActivityItem = ({ i, item, teacher, classId, activityFeed, setActivityFeed, setSection }) => {
    const getBookType = (i) => {
        return i ? BOOK_TYPES.find(t => t.type === i).label : 'Group Book'
    }

    const likeBtnDisabled = (item.likedBy && item.likedBy.find(el => el.id === teacher?.id)) || item.activityType === ACTIVITY_TYPES.LOG_ADDED_BY_TEACHER_TYPE || item.activityType === ACTIVITY_TYPES.GROUP_BOOK_LOG_ADDED_TYPE;

    const [markLogAsLiked, { loading: liking }] = useMutation(MARK_LOG_AS_LIKED, {
        onCompleted: (data) => {
            if (data && data.markLogAsLiked && data.markLogAsLiked.errors && data.markLogAsLiked.errors.length > 0) {
                return;
            }
            if (data && data.markLogAsLiked && data.markLogAsLiked.logLiked) {
                const newFeed = activityFeed.map(el => el.id === item.id ? { ...el, likedBy: [...(el.likedBy || []), { id: teacher.id, name: teacher.displayName }] } : el)
                setActivityFeed(newFeed);
            }
        }
    });


    const [markLogAsSeen] = useMutation(MARK_LOG_AS_SEEN, {
        onCompleted: (data) => {
            if (data && data.markLogAsViewed && data.markLogAsViewed.errors && data.markLogAsViewed.errors.length > 0) {
                return;
            }
            if (data && data.markLogAsViewed && data.markLogAsViewed.logViewed) {
                const newFeed = activityFeed.map(el => el.id === item.id ? { ...el, viewedBy: [...(el.viewedBy || []), { id: teacher.id, name: teacher.displayName }] } : el)
                setActivityFeed(newFeed);
            }
        }
    });

    const [markReviewAsLiked, { loading: likingReview }] = useMutation(MARK_REVIEW_AS_LIKED, {
        onCompleted: (data) => {
            if (data && data.markBookReviewAsLiked && data.markBookReviewAsLiked.errors && data.markBookReviewAsLiked.errors.length > 0) {
                return;
            }
            if (data && data.markBookReviewAsLiked && data.markBookReviewAsLiked.reviewLiked) {
                const newFeed = activityFeed.map(el => el.id === item.id ? { ...el, likedBy: [...(el.likedBy || []), { id: teacher.id, name: teacher.displayName }] } : el)
                setActivityFeed(newFeed);
            }
        }
    });

    const [markReviewAsSeen] = useMutation(MARK_REVIEW_AS_SEEN, {
        onCompleted: (data) => {
            if (data && data.markBookReviewAsViewed && data.markBookReviewAsViewed.errors && data.markBookReviewAsViewed.errors.length > 0) {
                return;
            }
            if (data && data.markBookReviewAsViewed && data.markBookReviewAsViewed.reviewViewed) {
                const newFeed = activityFeed.map(el => el.id === item.id ? { ...el, viewedBy: [...(el.viewedBy || []), { id: teacher.id, name: teacher.displayName }] } : el)
                setActivityFeed(newFeed);
            }
        }
    });

    const intersectionRef = useRef(null);
    const [itemVisible, setItemVisible] = useState(false);

    const teacherOfClass = classId && teacher?.studentsClasses.find(el => el.id === classId);

    const trackItemType = item.activityType === ACTIVITY_TYPES.LOG_ADDED_BY_PARENT_TYPE || item.activityType === ACTIVITY_TYPES.LOG_ADDED_BY_STUDENT_TYPE || item.activityType === ACTIVITY_TYPES.BOOK_REVIEW_ADDED_TYPE;
    const userShouldView = !ls('go_impersonating') && teacher?.school?.allowSeens && teacherOfClass && (!item.viewedBy || !item.viewedBy.find(el => el.id === teacher.id));

    const intersection = useIntersection(intersectionRef, {
        root: null,
        rootMargin: '0px',
        threshold: 1
    });

    useEffect(() => {
        if (intersection && intersection.intersectionRatio === 1 && trackItemType) {
            setItemVisible(true);
        }
    }, [intersection, trackItemType]);


    useEffect(() => {
        if (userShouldView && itemVisible) {
            if (item.activityType === ACTIVITY_TYPES.BOOK_REVIEW_ADDED_TYPE && item.readingBookReviewId) {
                markReviewAsSeen({
                    variables: {
                        readingBookReviewId: item.readingBookReviewId
                    }
                });
            } else if ((item.activityType === ACTIVITY_TYPES.LOG_ADDED_BY_STUDENT_TYPE || item.activityType === ACTIVITY_TYPES.LOG_ADDED_BY_PARENT_TYPE) && (item.studentReadingBookLogId || item.groupReadingBookLogId)) {
                //log added by parent/student can be on group book, so item will have groupReadingBookLogId
                markLogAsSeen({
                    variables: {
                        studentReadingBookLogId: item.studentReadingBookLogId || item.groupReadingBookLogId
                    }
                });
            }
        }
    }, [itemVisible, userShouldView, item]);


    const renderBlock = (i) => {
        switch (i.activityType) {
            case ACTIVITY_TYPES.LOG_ADDED_BY_TEACHER_TYPE:
            case ACTIVITY_TYPES.LOG_ADDED_BY_STUDENT_TYPE:
            case ACTIVITY_TYPES.LOG_ADDED_BY_PARENT_TYPE:
            case ACTIVITY_TYPES.GROUP_BOOK_LOG_ADDED_TYPE:
                return (
                    <>
                        {
                            <div className={styles.activityInner}>
                                <div className={styles.activityIcon}>
                                    <div className={cx(styles.iconBox, styles.iconBoxLog)}>
                                        <Icon.FileText color='#fff' size="20" />
                                    </div>
                                </div>
                                <div className={styles.activityMain}>
                                    <h3 className={styles.activityMainHeader}>Read Pages {i.fromPage} - {i.toPage === -1 ? 'The End' : i.toPage} <span className={styles.activityMainAuthor}>({i.author})</span></h3>
                                    <p>{i.bookName} ({getBookType(i.bookType)})</p>
                                    <div className='u-m-top-1'>
                                        {i.logComment && <TruncateLog str={i.logComment} />}

                                        {/* parents can like group logs, teacher logs & pupil logs */}
                                        {teacher?.school?.allowLikes &&
                                            <span className='u-m-right-1'>
                                                <Tooltip
                                                    disabled={!i.likedBy || i.likedBy.length === 0}
                                                    size='big'
                                                    animateFill={false}
                                                    html={<div>{i.likedBy && i.likedBy.map(el => <p key={`tip-${el.name}`}>{el.name}</p>)}</div>}
                                                >
                                                    <button
                                                        onClick={() =>
                                                            item.studentReadingBookLogId ?
                                                                markLogAsLiked({
                                                                    variables: { studentReadingBookLogId: item.studentReadingBookLogId }
                                                                }) : item.groupReadingBookLogId &&
                                                                markLogAsLiked({
                                                                    variables: {
                                                                        groupReadingBookLogId: item.groupReadingBookLogId,
                                                                        studentId: item.student?.id
                                                                    }
                                                                })
                                                        }
                                                        className={(cx(styles.likeButton, { [styles.likeButtonActive]: i.likedBy && i.likedBy.length > 0 }))}
                                                        disabled={likeBtnDisabled || liking}
                                                    ><Icon.Heart /></button>{i.likedBy && i.likedBy.length > 0 && <>({i.likedBy.length})</>}
                                                </Tooltip>
                                            </span>
                                        }

                                        {/* don't show to teacher their own seens on Parent Logs OR Student logs, but show parent seens on student logs */}
                                        {teacher?.school?.allowSeens && i.activityType !== ACTIVITY_TYPES.LOG_ADDED_BY_PARENT_TYPE &&
                                            <span className=''>
                                                <Tooltip
                                                    disabled={!i.viewedBy || i.viewedBy.filter(el => el.id !== teacher?.id).length === 0}
                                                    size='big'
                                                    animateFill={false}
                                                    html={<div>{i.viewedBy && i.viewedBy.filter(el => el.id !== teacher.id).map(el => <p key={`seen-${el.id}`}>{el.name}</p>)}</div>}
                                                >
                                                    <span
                                                        className={(cx(styles.seeButton, { [styles.seeButtonActive]: i.viewedBy && i.viewedBy.filter(el => el.id !== teacher?.id).length > 0 }))}
                                                    ><Icon.Eye /></span>{i.viewedBy && i.viewedBy.filter(el => el.id !== teacher?.id).length > 0 && <>({i.viewedBy.filter(el => el.id !== teacher?.id).length})</>}
                                                </Tooltip>
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                )
            case ACTIVITY_TYPES.COMPLETED_BOOK:
            case ACTIVITY_TYPES.GROUP_BOOK_COMPLETED:
                return (
                    <div className={styles.activityInner}>
                        <div className={styles.activityIcon}>
                            <div className={cx(styles.iconBox, styles.iconBoxCompleted)}></div>
                        </div>
                        <div className={styles.activityMain}>
                            <h3 className={styles.activityMainHeader}>Completed a book </h3>
                            <p>{i.bookName} ({getBookType(i.bookType)})</p>
                        </div>
                    </div>
                )
            case ACTIVITY_TYPES.BOOK_REVIEW_ADDED_TYPE:
                return (
                    <div className={styles.activityInner}>
                        <div className={styles.activityIcon}>
                            <div className={cx(styles.iconBox, styles.iconBoxReview)}>
                                <Icon.Star color='#fff' size="20" />
                            </div>
                        </div>
                        <div className={styles.activityMain}>
                            <h3 className={styles.activityMainHeader}>Reviewed a book {stars.map(star => {
                                return <Icon.Star key={`star${star}`} color="#000000" size="16" fill={star <= i.reviewRate ? '#FFD506' : 'none'} />
                            })}</h3>
                            <p>{i.bookName} ({getBookType(i.bookType)})</p>
                            <div className="u-m-top-1">
                                {i.reviewText && <span className='u-m-right-2'>{i.reviewText}</span>}

                                {/* parents can markAsSeen book reviews & teacher should see this */}
                                {teacher?.school?.allowSeens &&
                                    <span className='u-m-right-1'>
                                        <Tooltip
                                            disabled={!i.viewedBy || i.viewedBy.length === 0}
                                            size='big'
                                            animateFill={false}
                                            html={<div>{i.viewedBy && i.viewedBy.map(el => <p key={`seen-${el.id}`}>{el.name}</p>)}</div>}
                                        >
                                            <span
                                                className={(cx(styles.seeButton, { [styles.seeButtonActive]: i.viewedBy && i.viewedBy.length > 0 }))}
                                            ><Icon.Eye /></span>{i.viewedBy && i.viewedBy.length > 0 && <>({i.viewedBy.length})</>}
                                        </Tooltip>
                                    </span>
                                }

                                {teacher?.school?.allowLikes &&
                                    <Tooltip
                                        disabled={!i.likedBy || i.likedBy.length === 0}
                                        size='big'
                                        animateFill={false}
                                        html={<div>{i.likedBy && i.likedBy.map(el => <p key={`liked-${el.id}`}>{el.name}</p>)}</div>}
                                    >
                                        <button
                                            onClick={() => markReviewAsLiked({
                                                variables: { readingBookReviewId: item.readingBookReviewId }
                                            })}
                                            className={(cx(styles.likeButton, { [styles.likeButtonActive]: i.likedBy && i.likedBy.length > 0 }))}
                                            disabled={(i.likedBy && i.likedBy.find(el => el.id === teacher.id)) || likingReview}
                                        ><Icon.Heart /></button>{i.likedBy && i.likedBy.length > 0 && <>({i.likedBy.length})</>}
                                    </Tooltip>
                                }
                            </div>
                        </div>
                    </div>
                )
            case ACTIVITY_TYPES.NEW_READING_BAND_RANK_TYPE:
                return (
                    <div className={styles.activityInner}>
                        <div className={styles.activityIcon}>
                            <div className={cx(styles.iconBox, styles.iconBoxBand)}>
                                <BandIcon color={i.rankColourCode} />
                            </div>
                        </div>
                        <div className={styles.activityMain}>
                            <p className={styles.activityMainHeader}>New Reading Band - {i.rankName}</p>
                        </div>
                    </div>
                )
            case ACTIVITY_TYPES.GROUP_BOOK_ADDED:
            case ACTIVITY_TYPES.BOOK_ASSIGNED_TYPE:
                return (
                    <div className={styles.activityInner}>
                        <div className={styles.activityIcon}>
                            <div className={cx(styles.iconBox, styles.iconBoxNewBook)}></div>
                        </div>
                        <div className={styles.activityMain}>
                            <h3 className={styles.activityMainHeader}>New Book Added</h3>
                            {<p>{i.bookName} ({getBookType(i.bookType)})</p>}
                        </div>
                    </div>
                )
            case ACTIVITY_TYPES.TEACHER_NOTE:
                return (
                    <div className={styles.activityInner}>
                        <div className={styles.activityIcon}>
                            <div className={cx(styles.iconBox, styles.iconBoxNote)}>
                                <Icon.Clipboard color="#fff" size="20" />
                            </div>
                        </div>
                        <div className={styles.activityMain}>
                            <h3 onClick={() => setSection('notes')} className={styles.activityMainHeaderLink}>Note added  <span className={styles.activityMainAuthor}>({i.author})</span></h3>
                        </div>
                    </div>
                )
            default:
                return <p>Did something</p>
        }
    }


    return (
        <div ref={intersectionRef} className={styles.activity}>
            {(i === 0 || (activityFeed[i - 1] && moment(item.activityDate).format("DD/MM/YYYY") !== moment(activityFeed[i - 1].activityDate).format("DD/MM/YYYY"))) &&
                <>
                    <p className={styles.activityDate}>
                        {moment(item.activityDate).format("DD")}<br />
                        <span className={styles.activityDateSub}>{moment(item.activityDate).format("MMM")}</span>
                    </p>
                    <span className={styles.activityDot}>&nbsp;</span>
                </>
            }
            {/* {trackItemType && <p>SEEN: {item.viewedBy && item.viewedBy.map(el => <span key={`vv-${el.id}`}>{el.name}</span>)}</p>} */}
            {renderBlock(item)}
        </div>
    )
}

export default ActivityItem;