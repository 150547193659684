export const RB_NEW = 0;
export const RB_ACTIVE = 1;
export const RB_COMPLETE = 2;


export const LOG_BY_TEACHER = 1;
export const LOG_BY_STUDENT = 2
export const LOG_BY_PARENT = 3;
export const GROUP_BOOK_LOG = 4

export const FLAGS = [
    { name: 'Green', color: '#8ECB18', type: 1 },
    { name: 'Blue', color: '#3676FF', type: 2 },
    { name: 'Yellow', color: '#F7DC03', type: 3 },
    { name: 'Orange', color: '#FF9847', type: 4 },
    { name: 'Red', color: '#F71A01', type: 5 },

]

export const getFlagColor = (flagType) => {
    return FLAGS.find(f => f.type === flagType)?.color ?? '#ccc'
}

export const ACTIVITY_TYPES = {
    LOG_ADDED_BY_TEACHER_TYPE: 1,
    LOG_ADDED_BY_STUDENT_TYPE: 2,
    LOG_ADDED_BY_PARENT_TYPE: 3,
    GROUP_BOOK_LOG_ADDED_TYPE: 4,
    BOOK_REVIEW_ADDED_TYPE: 5,
    NEW_READING_BAND_RANK_TYPE: 6,
    BOOK_ASSIGNED_TYPE: 7,
    GROUP_BOOK_ADDED: 8,
    COMPLETED_BOOK: 9,
    TEACHER_NOTE: 10,
    MULTIPLE_GROUP_BOOK_ADDED: 12,
    MULTIPLE_GROUP_BOOK_LOG_ADDED: 13,
    MULTIPLE_GROUP_BOOK_COMPLETED: 14,
    GROUP_BOOK_COMPLETED: 19
}

export const ACTIVITY_LABELS = [
    {
        types: [ACTIVITY_TYPES.LOG_ADDED_BY_TEACHER_TYPE, ACTIVITY_TYPES.GROUP_BOOK_LOG_ADDED_TYPE],
        label: 'Teacher log'
    },
    {
        types: [ACTIVITY_TYPES.LOG_ADDED_BY_STUDENT_TYPE],
        label: 'Student log'
    },
    {
        types: [ACTIVITY_TYPES.LOG_ADDED_BY_PARENT_TYPE],
        label: 'Parent log'
    },
    {
        types: [ACTIVITY_TYPES.BOOK_REVIEW_ADDED_TYPE],
        label: 'Book Review'
    },
    {
        types: [ACTIVITY_TYPES.NEW_READING_BAND_RANK_TYPE],
        label: 'New Reading band'
    },
    {
        types: [ACTIVITY_TYPES.BOOK_ASSIGNED_TYPE, ACTIVITY_TYPES.GROUP_BOOK_ADDED],
        label: 'New book added'
    },
    {
        types: [ACTIVITY_TYPES.COMPLETED_BOOK],
        label: 'Completed book'
    },
    {
        types: [ACTIVITY_TYPES.TEACHER_NOTE],
        label: 'Notes'
    },
]


export const studentReadingBookQuery = `
id
book {
    id
    coverUrl
    title
    author
    publisher {
        id
        name
    }
    isbn
    isbn13
    reviews {
        id
        author {
            id
        }
        reviewText
        reviewRate
        date
    }
}
mostRecentLog {
    id
    date
    isCompletion
    readingDate
    entry
    book {
        id
        bookType
        book {
            id
            title
        }
    }
    author {
        id
        name
        role
        displayName
    }
    problemWords {
        id
        word
    }
    pageStart
    pageEnd
}
status
completedAt
createdAt
hideComments
isReread
bookType
`;