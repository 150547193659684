import { gql } from 'apollo-boost';

export const AWARD_GEM_MUTATION = gql`
mutation addRewardGemToStudent($studentId: ID!) {
    addRewardGemToStudent(studentId: $studentId) {
        student {
            id
        }
        errors
    }
}
`;

export const STUDENTS_AWARD_GEM_MUTATION = gql`
mutation addRewardGemToStudents($studentIds: [ID!]!) {
    addRewardGemToStudents(studentIds: $studentIds) {
        studentIds
        errors
    }
}
`;