import React, { useState, useEffect, useContext, useRef } from 'react';
import { MARK_REVIEW_AS_LIKED, MARK_REVIEW_AS_SEEN, GET_BOOK_REVIEW } from '../services/graphql';
import { useMutation } from '@apollo/react-hooks';
import { useIntersection } from 'react-use';
import * as Icon from 'react-feather';
import { Tooltip } from 'react-tippy';
import { BooksContext } from '../../../services/books/BooksProvider';

import ls from 'local-storage';
import cx from 'classnames';
import styles from './Search.module.scss';

const ReviewAcknowledgement = ({ teacher, classId, review, book}) => {


    const intersectionRef = useRef(null);

    const bookContext = useContext(BooksContext)
    const { activityFeed, setActivityFeed } = bookContext;

    const likeBtnDisabled = (review.likes && review.likes.find(el => el.author.id === teacher?.id));

    const [markReviewAsLiked, { loading: liking }] = useMutation(MARK_REVIEW_AS_LIKED, {
        onCompleted: (data) => {
            if (data && data.markReviewAsLiked && data.markReviewAsLiked.errors && data.markReviewAsLiked.errors.length > 0) {
                return;
            }
            if (data && data.markReviewAsLiked && data.markReviewAsLiked.reviewLiked) {
                const newFeed = activityFeed.map(el => el.readingBookReviewId === review.id ? { ...el, likedBy: [...(el.likedBy || []), { id: teacher.id, name: teacher.displayName }] } : el)
                setActivityFeed(newFeed);
            }
        },
        refetchQueries: [{ query: GET_BOOK_REVIEW, variables: { id: book.id } }]
    });

    const [markReviewAsSeen] = useMutation(MARK_REVIEW_AS_SEEN, {
        onCompleted: (data) => {
            if (data && data.markReviewAsViewed && data.markReviewAsViewed.errors && data.markReviewAsViewed.errors.length > 0) {
                return;
            }
            if (data && data.markReviewAsViewed && data.markReviewAsViewed.reviewViewed) {
                const newFeed = activityFeed.map(el => el.readingBookReviewId === review.id ? { ...el, viewedBy: [...(el.viewedBy || []), { id: teacher.id, name: teacher.displayName }] } : el)
                setActivityFeed(newFeed);
            }
        },
        refetchQueries: [{ query: GET_BOOK_REVIEW, variables: { id: book.id } }]
    });

    const [itemVisible, setItemVisible] = useState(false);

    const teacherOfClass = classId && teacher?.studentsClasses.find(el => el.id === classId);
    const userShouldView = !ls('go_impersonating') && teacher?.school?.allowSeens && teacherOfClass && (!review.views || !review.views.find(el => el.author.id === teacher.id));

    const intersection = useIntersection(intersectionRef, {
        root: null,
        rootMargin: '0px',
        threshold: 1
    });

    useEffect(() => {
        if (intersection && intersection.intersectionRatio === 1) {
            setItemVisible(true);
        }
    }, [intersection]);

    useEffect(() => {
        if (userShouldView && itemVisible) {
            markReviewAsSeen({
                variables: {
                    readingBookReviewId: review.id
                }
            });
        }
    }, [itemVisible, userShouldView]);

    return (
        <span ref={intersectionRef} className='u-m-left-2'>
            {teacher?.school?.allowLikes &&
                <span className='u-m-right-1'>
                    <Tooltip
                        disabled={!review.likes || review.likes.length === 0}
                        size='big'
                        animateFill={false}
                        html={<div>{review.likes && review.likes.map(el => <p key={`tip-${el.id}`}>{el.author.displayName}</p>)}</div>}
                    >
                        <button
                            onClick={() => markReviewAsLiked({
                                variables: { readingBookReviewId: review.id }
                            })}
                            className={(cx(styles.likeButton, { [styles.likeButtonActive]: review.likes && review.likes.length > 0 }))}
                            disabled={likeBtnDisabled || liking}
                        ><Icon.Heart /></button>{review.likes && review.likes.length > 0 && <>({review.likes.length})</>}
                    </Tooltip>
                </span>
            }

            {/* don't show to teacher their own seens on Parent Logs OR Student logs, but show parent seens on student logs */}
            {teacher?.school?.allowSeens &&
                <span className=''>
                    <Tooltip
                        disabled={!review.views || review.views.filter(el => el.author.id !== teacher?.id).length === 0}
                        size='big'
                        animateFill={false}
                        html={<div>{review.views && review.views.filter(el => el.author.id !== teacher.id).map(el => <p key={`seen-${el.id}`}>{el.author.displayName}</p>)}</div>}
                    >
                        <span
                            className={(cx(styles.seeButton, { [styles.seeButtonActive]: review.views && review.views.filter(el => el.author.id !== teacher.id).length > 0 }))}
                        ><Icon.Eye /></span>{review.views && review.views.filter(el => el.author.id !== teacher.id).length > 0 && <>({review.views.filter(el => el.author.id !== teacher.id).length})</>}
                    </Tooltip>
                </span>
            }
        </span>
    )
}

export default ReviewAcknowledgement;