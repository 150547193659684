import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import { parse } from "query-string";
import * as Icon from 'react-feather';
import StudentTile from '../StudentTile';
import { Dropdown, Modal, Button } from '@axeedge/go-teacher-components';
import gem from '../../../../components/GemButton/images/gem-green.svg';
import { CheckCircle, Flag } from 'react-feather';
import { STUDENTS_AWARD_GEM_MUTATION } from '../../../../components/GemButton/services/graphql';
import { CLASS_STATS_PER_INTERVAL, SET_STUDENTS_FLAG } from '../../services/graphql';
import { ClassFilterContext } from '../../../../services/class/ClassFilterProvider';
import styles from './StudentTileList.module.scss';
import CustomReadDates from '../CustomReadDates';
import iconTeacher from '../../images/icon_teacher.png';
import iconParent from '../../images/icon_home.png';
import iconBook from '../../images/icon_book.svg';
import iconBoomer from '../../../../images/boomer-icon.png';
import iconBand from '../../../../images/icons/band-alt.svg';
import ClassActionCable from '../../ClassActionCable';
import SaveConfirm from '../../../../components/SaveConfirm';
import Activity from '../Activity/activity'
import BookSearch from '../../../../components/BookSearch';
import Groups from '../Groups'
import ParentData from '../ParentData';
import ClassStats from '../ClassStats';
import StudentRow from '../StudentRow';
import ls from 'local-storage';
import { Tooltip } from 'react-tippy';

import { useLocation } from 'react-use';
import { FLAGS } from '../../../../utils/constants';

const StudentTileList = ({ students = [], teacher, classInfo = {}, school = {}, allLoaded, studentView = false, initiateStats, classLoading, studentsData, backToClass, setBackToSchoolStats }) => {

    const { t } = useTranslation();
    const classFilterContext = useContext(ClassFilterContext);
    const [baseStudents, setBaseStudents] = useState(studentsData)
    const [sortedStudents, setSortedStudents] = useState(baseStudents);

    const [classViewLayout, setClassViewLayout] = useState(ls('class_layout') || 'list');

    const studentRef = useRef();

    studentRef.current = baseStudents;

    const location = useLocation();


    useEffect(() => {
        if (studentsData !== baseStudents) {
            setBaseStudents(studentsData)
        }
    }, [studentsData])


    const [readDatePicker, setReadDatePicker] = useState(false);

    const { sortType, setSortType, viewType, setViewType, intervalStats, setIntervalStats, sortOrder, setSortOrder } = classFilterContext;

    const [chosenDateRange, setChosenDateRange] = useState({
        startDate: (intervalStats && intervalStats.startDate) || null,
        endDate: (intervalStats && intervalStats.endDate) || null
    })
    const [searchTerm, setSearchTerm] = useState("");
    const viewStats = parse(location.search).viewStats;
    const viewParents = parse(location.search).viewParents

    const [selectedStudents, setSelectedStudents] = useState([]);
    const [gemAwards, setGemAwards] = useState({ modal: false, awarded: false });
    const [flagged, setFlagged] = useState(false);
    const [bookSearch, setBookSearch] = useState(false);
    const [viewFilter, setViewFilter] = useState(viewStats ? 'stats' : 'activity')

    useEffect(() => {
        viewStats && setBackToSchoolStats(true);
        viewParents && setViewFilter('parents')
    }, [])

    useEffect(() => {
        if (backToClass) {
            setViewFilter('students')
            initiateStats(true)
        }
    }, [])

    useEffect(() => {
        const results = baseStudents.filter(student => student.name.toLowerCase().includes(searchTerm.toLowerCase()));
        setSortedStudents(results);
    }, [searchTerm, baseStudents]);

    const [awardGems, { loading }] = useMutation(STUDENTS_AWARD_GEM_MUTATION, {
        variables: {
            studentIds: selectedStudents
        },
        onCompleted: ({ addRewardGemToStudents }) => {
            if (addRewardGemToStudents.errors && addRewardGemToStudents.errors.length !== 0) {
                alert(t('error_awarding_gem'));
                return;
            }
            if (addRewardGemToStudents.studentIds) {
                setGemAwards({ ...gemAwards, awarded: true });
                setSelectedStudents([]);
            }
        }
    });

    const onSelectStudent = e => {
        if (selectedStudents.indexOf(e.target.value) !== -1) {
            setSelectedStudents(selectedStudents.filter(s => s !== e.target.value));
        } else {
            setSelectedStudents([...selectedStudents, e.target.value]);
        }
    }

    const onSelectAllStudents = e => {
        const stIds = students.map(student => student.id);
        e.target.checked ? setSelectedStudents(stIds) : setSelectedStudents([])
    }

    useEffect(() => {
        if (sortType === 'az') {
            setSortedStudents([...baseStudents]);
        } else if (sortType === 'flagged') {
            setSortedStudents([...baseStudents].filter(student => student.flagType));
        } else if (sortType.includes('books')) {
            let sType = sortType;
            sType = viewType === 'yearRead' || viewType === 'lastRead' ? 'currentYearBooksCounter' : viewType === 'weekRead' ? 'currentWeekBooksCounter' : sortType;
            setSortedStudents(_.orderBy(baseStudents, [s => s.stats[sType] || ''], [sortOrder]));
        } else {
            if (sortType.includes('last')) {
                setSortedStudents(_.orderBy(baseStudents, [s => !s.stats[sortType], s => s.stats[sortType]], ['desc', 'asc']))
            } else {
                let sType = sortType;
                if (viewType === 'yearRead') {
                    sType = `currentYear${sType.slice(0, 1).toUpperCase()}${sType.slice(1, sType.length)}Counter`;
                }
                if (sType.includes('parent')) {
                    setSortedStudents(_.orderBy(baseStudents, [s => s.parentsCounter, s => s.stats[sType] || ''], ['desc', 'desc']))
                } else {
                    setSortedStudents(_.orderBy(baseStudents, [s => s.stats[sType] || ''], ['desc']))
                }
            }
        }
    }, [sortType, baseStudents, viewType, sortOrder])

    const sortOptions = allLoaded ? [
        { text: 'A-Z', type: 'az', action: () => setSortType('az'), styleName: cx({ 'u-text-primary': sortType === 'az' }) },
        { text: t('since_last_teacher_read'), type: 'lastTeacherLog', action: () => setSortType('lastTeacherLog'), styleName: cx({ 'u-text-primary': sortType === 'lastTeacherLog' }) },
        { text: t('since_last_parent_read'), type: 'lastParentLog', action: () => setSortType('lastParentLog'), styleName: cx({ 'u-text-primary': sortType === 'lastParentLog' }) },
        { text: t('since_last_pupil_read'), type: 'lastStudentLog', action: () => setSortType('lastStudentLog'), styleName: cx({ 'u-text-primary': sortType === 'lastStudentLog' }) },
        { text: t('most_teacher_reads'), type: viewType === 'customRead' ? 'interval_teacher_logs' : 'teacherLogs', action: () => setSortType(viewType === 'customRead' ? 'interval_teacher_logs' : 'teacherLogs'), styleName: cx({ 'u-text-primary': sortType === 'teacherLogs' }) },
        { text: t('most_parent_reads'), type: viewType === 'customRead' ? 'interval_parent_logs' : 'parentLogs', action: () => setSortType(viewType === 'customRead' ? 'interval_parent_logs' : 'parentLogs'), styleName: cx({ 'u-text-primary': sortType === 'parentLogs' }) },
        { text: t('most_pupil_reads'), type: viewType === 'customRead' ? 'interval_student_logs' : 'studentLogs', action: () => setSortType(viewType === 'customRead' ? 'interval_student_logs' : 'studentLogs'), styleName: cx({ 'u-text-primary': sortType === 'studentLogs' }) },
        { text: t('flagged'), type: 'flagged', action: () => setSortType('flagged'), styleName: cx({ 'u-text-primary': sortType === 'flagged' }) },
        { text: t('most_books_read'), order: 'desc', type: viewType === 'customRead' ? 'interval_books_counter' : 'most_books_read', action: () => { setSortType(viewType === 'customRead' ? 'interval_books_counter' : 'most_books_read'); setSortOrder('desc') }, styleName: cx({ 'u-text-primary': sortType === 'most_books_read' }) },
        { text: t('least_books_read'), order: 'asc', type: viewType === 'customRead' ? 'interval_books_counter' : 'least_books_read', action: () => { setSortType(viewType === 'customRead' ? 'interval_books_counter' : 'least_books_read'); setSortOrder('asc') }, styleName: cx({ 'u-text-primary': sortType === 'least_books_read' && sortOrder === 'asc' }) },
    ] : []

    const sortTitle = sortOptions.find(o => o.type === sortType && (o.order ? o.order === sortOrder : true))?.text || '';

    const displayOptions = allLoaded ? [
        { text: t('date_of_last_read'), type: 'lastRead', action: () => setViewType('lastRead'), styleName: cx({ 'u-text-primary': viewType === 'lastRead' }) },
        { text: t('no_of_reads_this_week'), type: 'weekRead', action: () => setViewType('weekRead'), styleName: cx({ 'u-text-primary': viewType === 'weekRead' }) },
        { text: (intervalStats && intervalStats.startDate) ? `Reads from: ${intervalStats.startDate} to ${intervalStats.endDate}` : t('no_of_reads_between_dates'), type: 'customRead', action: () => setReadDatePicker(true), styleName: cx({ 'u-text-primary': viewType === 'customRead' }) },
        { text: t('no_of_reads_this_year'), type: 'yearRead', action: () => setViewType('yearRead'), styleName: cx({ 'u-text-primary': viewType === 'yearRead' }) },
    ] : []


    const viewTitle = displayOptions.find(o => o.type === viewType)?.text || '';

    const [st, setSt] = useState([]);
    const [test, setTest] = useState([])
    const stateRef = useRef();
    stateRef.current = test;

    const [classStatsPerInterval] = useMutation(CLASS_STATS_PER_INTERVAL, {
        onCompleted: ({ studentsClassStatsPerInterval }) => {
            if (studentsClassStatsPerInterval.errors && studentsClassStatsPerInterval.errors.length !== 0) {
                alert(t('There was an error, please try again'));
                return;
            }
            if (studentsClassStatsPerInterval.studentsClass && studentsClassStatsPerInterval.studentsClass.id) {
                if (viewType !== 'customRead') {
                    setViewType('customRead');
                }
                if (readDatePicker) {
                    setReadDatePicker(false);
                }
                setTest([])
                setSt([]);

                setIntervalStats({
                    ...intervalStats,
                    startDate: chosenDateRange.startDate,
                    endDate: chosenDateRange.endDate
                });
            }
        }
    });

    useEffect(() => {

        if (viewType === 'customRead' && allLoaded && intervalStats.startDate && intervalStats.endDate) {
            classStatsPerInterval({
                variables: {
                    studentsClassId: classInfo.id,
                    startDate: intervalStats.startDate,
                    endDate: intervalStats.endDate
                }
            });
        }
    }, [allLoaded]);

    const onSetCustomRange = dates => {
        setChosenDateRange({
            ...chosenDateRange,
            startDate: moment(dates.startDate).format("DD/MM/YYYY"),
            endDate: moment(dates.endDate).format("DD/MM/YYYY")
        });
        classStatsPerInterval({
            variables: {
                studentsClassId: classInfo.id,
                startDate: dates.startDate,
                endDate: dates.endDate
            }
        });
    }

    const setStudentIntervalStats = student => {
        let array = stateRef.current.concat(student);
        let newStudents = [];
        studentRef.current.forEach(s => {
            if (s.id === student.id) {
                const tempStud = {
                    ...s,
                    stats: {
                        ...s.stats,
                        interval_student_logs: student.interval_student_logs,
                        interval_parent_logs: student.interval_parent_logs,
                        interval_teacher_logs: student.interval_teacher_logs,
                        interval_books_counter: student.interval_books_counter,
                    }
                }
                newStudents.push(tempStud)
            } else {
                newStudents.push(s)
            }
        })
        setBaseStudents(newStudents)
        setTest(array)
    }

    useEffect(() => {
        setSt(test)
    }, [setStudentIntervalStats]);

    useEffect(() => {
        ls('class_layout', classViewLayout);
    }, [classViewLayout])



    const flagOptions = allLoaded ? [
        ...FLAGS.map(flag => {
            return { text: <span className='u-display-flex u-align-center light'><Icon.Flag size="20" fill={flag.color} color={flag.color} className={cx(styles.flagBtn, 'u-m-right-1')} /> {flag.name}</span>, action: () => flagMultipleStudents(flag.type, false) }
        }),
        {
            text: <span className='u-display-flex u-align-center light'><Icon.Flag size="20" color={'#ccc'} className={cx(styles.flagBtn, 'u-m-right-1')} /> Un-flag</span>, action: () => flagMultipleStudents(null, true)
        }
    ] : []

    const [setStudentsFlag] = useMutation(SET_STUDENTS_FLAG, {
        onCompleted: ({ setStudentsFlag }) => {
            if (setStudentsFlag.students) {
                setSelectedStudents([]);
                setFlagged(true);
            }
        }
    });

    const flagMultipleStudents = (type, remove) => {
        setStudentsFlag({
            variables: {
                studentIds: selectedStudents,
                flagType: type,
                removeFlags: remove
            }
        })
    }

    return (
        <Fragment>
            <ClassActionCable teacherId={teacher.id} classId={classInfo.id} setCustomStats={setStudentIntervalStats} />
            {!bookSearch ?
                <>
                    {
                        students.length > 0 ? (
                            <>
                                <div className={cx(styles.classActions, 'hidden-print')}>
                                    <div className={styles.classActionsMain}>
                                        <Tooltip title="Class Feed" size="big"><button className={cx(styles.searchBarFilter, viewFilter === 'activity' && styles.searchBarFilterActive)} onClick={() => setViewFilter("activity")}><Icon.Activity />Activity</button></Tooltip>
                                        <Tooltip title="Class Page" size="big"><button className={cx(styles.searchBarFilter, viewFilter === 'students' && styles.searchBarFilterActive)} onClick={() => { setViewFilter("students"); initiateStats(true) }}><Icon.BookOpen />Class</button></Tooltip>
                                        <Tooltip title="Group Books" size="big"><button className={cx(styles.searchBarFilter, viewFilter === 'groups' && styles.searchBarFilterActive)} onClick={() => setViewFilter("groups")}><Icon.Users />Groups</button></Tooltip>
                                        <Tooltip title="Class Data" size="big"><button className={cx(styles.searchBarFilter, viewFilter === 'stats' && styles.searchBarFilterActive)} onClick={() => { setViewFilter("stats"); initiateStats(true) }}><Icon.TrendingUp />Data</button></Tooltip>
                                        <Tooltip title="Parent Data" size="big"><button className={cx(styles.searchBarFilter, viewFilter === 'parents' && styles.searchBarFilterActive)} onClick={() => setViewFilter("parents")}><Icon.Home />Parents</button></Tooltip>
                                        <Tooltip title="Login Sheets" size="big"><a className={styles.searchBarFilter} href={`/class/${classInfo.id}?printStudents=true`} target='_blank' rel="noopener noreferrer">
                                            <Icon.Printer /> Login sheets
                                        </a></Tooltip>
                                        <Tooltip title="Parent Letters" size="big"><a className={styles.searchBarFilter} href={`/class/${classInfo.id}?printParentLetters=true`} target='_blank' rel="noopener noreferrer">
                                            <Icon.Mail />  Parent letters
                                        </a></Tooltip>
                                    </div>
                                </div>

                                {viewFilter === 'students' &&
                                    <>
                                        <div className={cx(styles.classActionsSub, 'hidden-print')}>
                                            {!studentView &&
                                                <div className={styles.classActionsSelectAll}>
                                                    <div className={cx("basic-form__group--check", styles.stCheck)}>
                                                        <input
                                                            name="all"
                                                            className="basic-form__check-box"
                                                            type="checkbox"
                                                            checked={selectedStudents.length === sortedStudents.length && sortedStudents.length > 0}
                                                            onChange={(e) => onSelectAllStudents(e)}
                                                            id="selectAll"
                                                        />
                                                        <label className="basic-form__check-label" htmlFor='selectAll'>&nbsp;</label>
                                                    </div>
                                                    <button onClick={() => setGemAwards({ ...gemAwards, modal: true })} disabled={selectedStudents.length === 0} className={cx(styles.classActionsLink, styles.classActionsLinkIcon, 'u-m-left-2')}><img src={gem} className='u-m-right-1' alt='' /> Bonus</button>
                                                    <button onClick={() => setBookSearch(true)} disabled={selectedStudents.length === 0} className={cx(styles.classActionsLink, styles.classActionsLinkIcon)}><Icon.PlusCircle />Group Book</button>
                                                    <Dropdown
                                                        titleRight={t('flag')}
                                                        leftAligned
                                                        icon={Flag}
                                                        dropdownOpts={flagOptions}
                                                        classNames={cx(styles.classActionsLink, styles.classActionsLinkFlag, { [styles.classActionsLinkDisabled]: selectedStudents.length === 0 })}
                                                    />
                                                </div>
                                            }
                                            <Dropdown icon={Icon.ChevronDown} dropdownOpts={sortOptions} classNames={cx(styles.classActionsLink, 'u-m-right-2')} leftAligned titleLeft={allLoaded ? <>View By: <span className="u-text-primary">{sortTitle}</span></> : t('loading')} />
                                            <Dropdown icon={Icon.ChevronDown} dropdownOpts={displayOptions} classNames={cx(styles.classActionsLink, styles.classActionsButtonRange)} leftAligned titleLeft={allLoaded ? <>Display: <span className="u-text-primary">{viewTitle}</span></> : t('loading')} />
                                            <div className={styles.classActionsForm}>
                                                <input
                                                    type="text"
                                                    className={cx('basic-form__text-box', styles.classActionsSearch)}
                                                    value={searchTerm}
                                                    placeholder={t('search_pupils')}
                                                    onChange={e => setSearchTerm(e.target.value)}
                                                />
                                            </div>
                                            <div className={styles.classActionsToggleLayout}>
                                                <button className='btn-reset' onClick={() => setClassViewLayout('grid')}><Icon.Grid /> Grid</button>
                                                <button className='btn-reset' onClick={() => setClassViewLayout('list')}><Icon.AlignLeft /> List</button>
                                            </div>
                                        </div>
                                        {flagged && <SaveConfirm classNames='u-m-top-2' message={`${t('done')}!`} hideConfirm={() => setFlagged(false)} />}
                                    </>
                                }

                            </>
                        ) : <p className='u-m-top-3'>{t('no_pupils_in_this_class')}</p>
                    }
                    {viewFilter === "students" &&
                        <>
                            {!classLoading ?
                                <>{classViewLayout === 'list' ?
                                    <ul className={styles.classStudentList}>
                                        <li className={styles.classStudentListHeader}>
                                            <div className={styles.classStudentListData}>
                                                <div><img src={iconParent} width={20} alt='Parent reads' /></div>
                                                <div><img src={iconTeacher} width={24} alt='Teacher reads' /></div>
                                                <div><img src={iconBoomer} width={20} alt='Pupil reads' /></div>
                                                <div><img src={iconBook} width={15} alt='Books read' /></div>
                                                <div><img src={iconBand} width={15} alt='Readin band ' /></div>
                                            </div>
                                        </li>
                                        {sortedStudents.map(student => (
                                            <StudentRow
                                                key={`StudentList${student.id}`}
                                                student={{ ...student, school }}
                                                classInfo={classInfo}
                                                viewType={viewType}
                                                intervalStats={st}
                                                onSelectStudent={onSelectStudent}
                                                selectedStudents={selectedStudents}
                                                studentView={studentView}
                                            />
                                        ))}
                                    </ul>
                                    :
                                    <ul className={styles.classStudentsTiles}>
                                        {
                                            sortedStudents.map(student => (

                                                <StudentTile
                                                    key={`StudentList${student.id}`}
                                                    student={{ ...student, school }}
                                                    classInfo={classInfo}
                                                    viewType={viewType}
                                                    intervalStats={st}
                                                    onSelectStudent={onSelectStudent}
                                                    selectedStudents={selectedStudents}
                                                    studentView={studentView}
                                                />
                                            ))
                                        }
                                    </ul>
                                }
                                    {sortType === 'flagged' && sortedStudents.length === 0 && <p>You have no flagged students</p>}
                                </>
                                :
                                <p>Loading&hellip;</p>
                            }
                        </>
                    }
                    {viewFilter === 'stats' &&
                        <>
                            {!classLoading ?
                                <ClassStats classInfo={classInfo} students={students} /> :
                                <p>Loading&hellip;</p>}
                        </>
                    }
                    {viewFilter === 'parents' &&
                        <ParentData students={students} classInfo={classInfo} />
                    }
                    {viewFilter === "groups" &&
                        <Groups classId={classInfo.id} classInfo={classInfo} />
                    }
                </>
                :
                <BookSearch close={setBookSearch} studentIds={selectedStudents} classInfo={classInfo} setFilter={setViewFilter} />
            }
            <div style={{ display: (viewFilter === "activity" && !bookSearch) ? 'block' : 'none' }}>
                <Activity classId={classInfo.id} setViewFilter={setViewFilter} />
            </div>
            {gemAwards.modal && (
                <Modal closeModal={() => { setGemAwards({ modal: false, awarded: false }); setSelectedStudents([]) }}>
                    <h2 className='u-m-base-4 u-text-center'>{t('award_gem_to_multiple_pupils')}</h2>
                    {gemAwards.awarded ? <Button onClick={() => setGemAwards({ modal: false, awarded: false })} outline className={styles.gemButton}><CheckCircle /> {t('done')}!</Button> : (
                        <Button outline className={styles.gemButton} onClick={() => awardGems()}><img src={gem} alt='' />{loading ? t('loading') : t('award_bonus_gem')}</Button>
                    )}
                </Modal>
            )}

            {readDatePicker && (
                <Modal closeModal={() => setReadDatePicker(false)}>
                    <CustomReadDates onDisplayCustom={onSetCustomRange} />
                </Modal>
            )}
        </Fragment>
    )
}

export default StudentTileList;