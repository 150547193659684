import React, { useState, useEffect, useContext, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment';
import * as Icon from 'react-feather';
import cx from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useLocation } from 'react-use';
import { parse } from "query-string";
import { Link } from '@reach/router';
import { YEAR_GROUPS } from '@axeedge/go-shared-utils';

import BookSearch from '../../../components/BookSearch';
import { RB_COMPLETE } from '../../../utils/constants';
import { Button, Modal, Dropdown, FormErrors } from '@axeedge/go-teacher-components';
import SideModal from '../../../components/SideModal';
import LogNew from '../../../components/LogReading/LogNew';
import AssignBand from '../../Banding/components/assign';
import GemButton from '../../../components/GemButton';
import TypeLabel from '../../../components/BookTypeLabel';
import Search from './search';
import Activity from './activity';
import Notes from './Notes';
import AddNote from './Notes/AddNote';
import AllProblemWords from './allProblemWords';
import PrintableCode from './PrintableCode'

import { DELETE_READING_BOOK_MUTATION, DELETE_READING_BOOK_FROM_CLASS, EDIT_STUDENT_READING_BOOK, UNCOMPLETE_STUDENT_READING_BOOK } from '../../../services/readingBook/graphql';
import { GET_STUDENT_QUERY, EDIT_LOG, DELETE_LOG, GET_SINGLE_BOOK, GET_GROUP_BOOK } from '../services/graphql';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { AuthContext } from '../../../services/auth/AuthProvider';
import { BooksContext } from '../../../services/books/BooksProvider';
import { BOOK_TYPES, READING_BOOK_TYPE } from '../../../components/BookSearch/services/constants';
import { ASSIGN_READING_BOOK } from '../../../components/BookSearch/services/graphql';
import { EDIT_GROUP_BOOK, EDIT_GROUP_LOG, DELETE_GROUP_BOOK, DELETE_GROUP_LOG } from '../../../components/AddLog/services/graphql';

import Class from '../../ClassNew'

import LoginCode from './LoginCode';
import { useTranslation } from 'react-i18next';

import styles from '../Student.module.scss';
import classStyles from '../../ClassNew/components/StudentTileList/StudentTileList.module.scss';
import logStyles from '../../../components/LogReading/LogReading.module.scss'

import teacherIcon from '../../../images/usertypes/teacher.png';
import homeIcon from '../../../images/usertypes/parent.png';
import bookIcon from '../../../images/usertypes/book.svg';
import boomer from '../../../images/loader-boomer.png';
import survey from '../../../images/survey.png';

import loader from '../../../images/loader.svg';
import { Tooltip } from 'react-tippy';
import DataDownload from './DataDownload';
import LogAcknowledgement from './LogAcknowledgement';
import StudentBookReview from './StudentBookReview';
import SetFlag from './SetFlag';

const StudentContent = ({ student, classInfo, classId, showNotes }) => {

    const { t } = useTranslation();

    const [studentState, setStudentState] = useState(student);

    const completedSurveys = student?.studentSurveys?.filter(s => s.status === 2);

    const [sortType, setSortType] = useState('all');
    const [logOption, setLogOption] = useState('all');
    const [filterType, setFilterType] = useState('active');
    const [searchTerm, setSearchTerm] = useState('');
    const [search, setSearch] = useState(false);
    const [logSearch, setLogSearch] = useState(false);
    const [initiateActivity, setInitiateActivity] = useState(classInfo && classInfo.activity);
    const [codeCopy, setCodeCopy] = useState(null);
    const [viewClass, setViewClass] = useState(false);
    const [classInitiated, setClassInitiated] = useState(false);

    const [showProblemWords, setShowProblemWords] = useState()
    const [completeConfirmation, setCompleteConfirmation] = useState(false);
    const [initiateComplete, setInitiateComplete] = useState(false);
    const [showDataForm, setShowDataForm] = useState(false);
    const [showNoteForm, setShowNoteForm] = useState(false);


    const [section, setSection] = useState((classInfo && classInfo.activity) ? 'logs' : showNotes ? 'notes' : 'books');
    const location = useLocation();

    const [showInfo, setShowInfo] = useState(false);

    const [showBookForm, setShowBookForm] = useState((classInfo && classInfo.showAddBook) || false);
    const [showRefreshLoginCode, setShowRefreshLoginCode] = useState();

    const auth = useContext(AuthContext);
    const { currentUser: teacher, userPermissions } = auth;


    const [selectedBook, setSelectedBook] = useState({
        open: false,
        book: null,
    });
    const [showSuccess, setShowSuccess] = useState(false);
    const [showLogSuccess, setShowLogSucces] = useState(false);
    const [showCompleteLogSuccess, setShowCompleteLogSuccess] = useState(false);
    const [showEditSuccess, setShowEditSuccess] = useState(false);
    const [showLogDeleteSuccess, setShowLogDeleteSuccess] = useState(false);


    const [showBand, setShowBand] = useState({
        show: false,
        student: student,
        history: false,
    })


    const resetSearch = () => {
        setSortType('all')
        setFilterType('active')
        setSearchTerm('');
        setSearch(true);
    }

    const timeAgo = dateString => {
        if (!dateString) {
            return '--'
        }
        const local = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').local();
        return moment(local, "YYYY-MM-DD HH:mm:ss").fromNow();
    }

    useEffect(() => {
        if (studentState !== student) {
            setStudentState(student);
        }
    }, [student, studentState]);

    useEffect(() => {
        if (codeCopy !== null) {
            setTimeout(() => setCodeCopy(null), 1500);
        }
    }, [codeCopy])

    useEffect(() => {
        if (selectedBook.open) {
            document.body.classList.add('preventScroll');
        } else {
            document.body.classList.remove('preventScroll');
        }
    }, [selectedBook])

    useEffect(() => {
        if (showBand.show) {
            document.body.classList.add('preventScroll');
        } else {
            document.body.classList.remove('preventScroll');
        }
    }, [showBand])

    const closeBook = () => {
        setShowSuccess(false);
        setShowCompleteLogSuccess(false);
        setShowLogSucces(false);
        setShowLogDeleteSuccess(false);
        setShowEditSuccess(false)
        setSelectedBook({ book: null, open: false })
    }

    const displayLogAuthor = author => {
        switch (author.role) {
            case 'Parent':
                return author.displayName
            case 'Teacher':
                return teacher && teacher.id === author.id ? t('you') : author.displayName
            case 'Student':
                return author.displayName
            default:
                return '-'
        }
    }

    const getYearGroupName = yearGroup => {
        const yGroup = YEAR_GROUPS.find(yg => yg.val === yearGroup);
        return yGroup ? yGroup.name : '-'
    }

    const Log = ({ log, action, teacher, book }) => {
        const intersectionRef = useRef(null);

        var date = moment(log.readingDate);
        if (log.studentIds && log.studentIds.includes(student.id) || !log.studentIds) {
            return (
                <li ref={intersectionRef} className={styles.logListItem} >
                    <div className={styles.logListItemHeader}>
                        <div className={styles.logListItemHeaderMain}>
                            <span className={styles.logListItemHeaderDate}>{date.format("DD MMM YY")}</span>
                            <span className={styles.logListItemHeaderPages}><Icon.FileText size="20" className='u-text-muted u-mr-2' /> {log.pageStart} - {log.isCompletion ? 'The End' : log.pageEnd}</span>
                            {log.studentIds && log.studentIds.length > 1 && <span className={styles.logListItemHeaderUsers}><Icon.Users className='u-text-muted u-m-l-2' /></span>}
                            <LogAcknowledgement
                                intersectionRef={intersectionRef}
                                classId={classId || (classInfo && classInfo.id)}
                                log={log}
                                teacher={teacher}
                                book={book}
                            />
                        </div>
                        <div>{displayLogAuthor(log.author)}</div>
                    </div>
                    {log.entry !== "" &&
                        <div className={styles.logListSection}>
                            <p>{log.entry}</p>
                        </div>
                    }
                    {log.problemWords.length > 0 && (
                        <div className={styles.logListSection}>
                            {log.problemWords.map((w, i) => <span key={`word-${i}`} className='label label-dark u-m-right-5'>{w.word}</span>)}
                        </div>
                    )}
                    <Icon.Settings onClick={() => { action(log) }} className={styles.logListItemCog} />
                </li>
            )
        }
        return null
    }

    const words = (problemWords) => {
        let words = [];
        _.map(problemWords, word => {
            return words.push(word.word);
        })
        return words;
    }

    const EditLog = ({ log, close, book }) => {
        const bookContext = useContext(BooksContext)
        const { books, setBooks, activityFeed, setActivityFeed, classActivityFeed, setClassActivityFeed } = bookContext;

        const [formErrors, setFormErrors] = useState(false);
        const [confirmLogDelete, setConfirmLogDelete] = useState(null);

        const [form, setForm] = useState({
            comment: log.entry ? log.entry : '',
            problemWords: log.problemWords ? words(log.problemWords) : [],
        });

        const [editLog, { loading }] = useMutation(EDIT_LOG, {
            awaitRefetchQueries: true,
            update: (_, { data }) => {
                if (data.editReadingBookLog.errors && data.editReadingBookLog.errors.length !== 0) {
                    setFormErrors(true);
                    return;
                }
                if (data.editReadingBookLog) {
                    setShowLogSucces(true);
                }
            },
            refetchQueries: [{ query: GET_STUDENT_QUERY, variables: { id: student.id } }],
            variables: {
                studentReadingBookLogId: log.id,
                entry: form.comment,
                problemWords: form.problemWords
            }
        });

        const [editGroupLog, { loadingEditGroupLog }] = useMutation(EDIT_GROUP_LOG, {
            awaitRefetchQueries: true,
            refetchQueries: [{ query: GET_STUDENT_QUERY, variables: { id: student.id } }],
            variables: {
                groupReadingBookLogId: log.id,
                entry: form.comment,
                problemWords: form.problemWords,
            },
            update: (_, { data }) => {
                if (data.editGroupReadingBookLog.errors && data.editGroupReadingBookLog.errors.length !== 0) {
                    setFormErrors(true);
                    return;
                }
                if (data.editGroupReadingBookLog) {
                    setShowLogSucces(true);
                }
            },
        })

        const [deleteLog, { loading: deletingLog }] = useMutation(DELETE_LOG, {
            awaitRefetchQueries: true,
            update: (_, { data }) => {
                if (data.deleteReadingBookLog.errors && data.deleteReadingBookLog.errors.length !== 0) {
                    setFormErrors(true);
                    return;
                }
                if (data.deleteReadingBookLog && data.deleteReadingBookLog.deleted && data.deleteReadingBookLog.studentReadingBook) {
                    setShowLogDeleteSuccess(true);
                    setConfirmLogDelete(false);

                    const updatedBook = data.deleteReadingBookLog.studentReadingBook;
                    const newBooks = []
                    books.forEach(book => {
                        if (book.id === updatedBook.id) {
                            newBooks.push(updatedBook)
                        } else {
                            newBooks.push(book)
                        }
                    });
                    setBooks(newBooks);
                    setActivityFeed([...activityFeed.filter(x => x.studentReadingBookLogId !== log.id)])
                    setClassActivityFeed([...classActivityFeed.filter(x => x.studentReadingBookLogId !== log.id)])
                }
            },
            refetchQueries: [{ query: GET_STUDENT_QUERY, variables: { id: student.id } }, { query: GET_SINGLE_BOOK, variables: { id: book.id } }],
            variables: {
                studentReadingBookLogId: log.id,
                studentId: student.id
            }
        });

        const [deleteGroupLog, { loading: deletingGroupLog }] = useMutation(DELETE_GROUP_LOG, {
            awaitRefetchQueries: true,
            refetchQueries: [{ query: GET_STUDENT_QUERY, variables: { id: student.id } }, { query: GET_GROUP_BOOK, variables: { id: book.id } }],
            variables: {
                groupReadingBookLogId: log.id,
                studentId: student.id
            },
            update: (_, { data }) => {
                if (data.deleteReadingBookLog.errors && data.deleteReadingBookLog.errors.length !== 0) {
                    setFormErrors(true);
                    return;
                }
                if (data.deleteGroupReadingBookLog && data.deleteReadingBookLog.deleted && data.deleteReadingBookLog.studentReadingBook) {
                    setShowLogDeleteSuccess(true);
                    setConfirmLogDelete(false);

                    const updatedBook = data.deleteReadingBookLog.studentReadingBook;
                    const newBooks = []
                    books.forEach(book => {
                        if (book.id === updatedBook.id) {
                            newBooks.push(updatedBook)
                        } else {
                            newBooks.push(book)
                        }
                    });
                    setBooks(newBooks);
                }
            },
        })

        const handleEdit = (e) => {
            e.preventDefault();
            if (book.students && book.students.length > 0) {
                editGroupLog()
            } else {
                editLog();
            }
        }

        let date = moment(log.readingDate);

        const tagInput = useRef(null);

        const addWordTags = e => {
            const val = e.target.value;
            if ((e.key === 'Enter' || e.key === ',') && val && val.replace(/\s/g, '').length) {
                e.preventDefault();
                if (form.problemWords.find(word => word.replace(/\s/g, '').toLowerCase() === val.replace(/\s/g, '').toLowerCase())) {
                    return;
                }
                setForm({
                    ...form,
                    problemWords: [...form.problemWords, val]
                });
                tagInput.current.value = null;
            }
        };

        const removeWordTag = i => {
            const newWords = [...form.problemWords];
            newWords.splice(i, 1);
            setForm({
                ...form,
                problemWords: newWords
            });
        };

        return (
            <>
                <h1 className={cx(styles.bookSubHeading, "u-m-top-20")} onClick={close}><Icon.ArrowLeft className={styles.bookSubHeadingIcon} /> {t('history')}</h1>
                <div className={cx("u-m-top-20 u-m-base-20", styles.editHeader)}>
                    <div className={cx(styles.editMeta, "u-m-right-20")}>
                        <Icon.User className="u-m-right-5" /> {displayLogAuthor(log.author)}
                    </div>
                    <div className={styles.editMeta}>
                        <Icon.FileText className="u-m-right-5" /> {log.pageStart} - {log.isCompletion ? 'The End' : log.pageEnd}
                    </div>
                    <div className={cx(styles.editMeta, styles.editDate)}>
                        {date.format("DD MMM YY")}
                    </div>
                </div>
                <div className={styles.editPanel}>
                    <form onSubmit={(e) => handleEdit(e)}>
                        <label>{t('comment')}:</label>
                        <textarea className={cx(styles.logReadingComment, 'u-m-top-10 basic-form__text-box')} rows="3" placeholder={t('write_your_comment_here')} value={form.comment} onChange={(e) => setForm({ ...form, comment: e.currentTarget.value })}></textarea>
                        <div className={cx(styles.logReadingField, 'u-m-top-10')}>
                            <input
                                type="text"
                                placeholder={t('separate_words_comma_enter')}
                                className='basic-form__text-box'
                                onKeyPress={(e) => addWordTags(e)}
                                ref={tagInput}
                            />
                        </div>
                        <div className={cx(logStyles.logReadingField, 'u-m-top-10 u-m-base-20')}>
                            {_.map(form.problemWords, (word, i) => {
                                return (
                                    <span className={logStyles.logReadingProbWord} key={`probWord${i}`}>
                                        {word}
                                        <button className={logStyles.logReadingProbWordDelete} type="button" onClick={() => removeWordTag(i)}><Icon.X size="12" /></button>
                                    </span>
                                )
                            })}
                        </div>
                        {formErrors && <p className="error u-smaller u-m-base-10">{t('oops_something_went_wrong_try_again')}</p>}
                        <Button className="u-m-right-10" disabled={loading}>{loading ? t('updating') : t('button.update')}</Button>
                        <Button outline onClick={close}>{t('button.cancel')}</Button>
                    </form>
                </div>
                <Button className='btn-danger u-m-top-3' onClick={() => setConfirmLogDelete(true)}>{deletingLog ? t('saving') : t('button.delete')}</Button>

                {confirmLogDelete &&
                    <Modal closeModal={() => setConfirmLogDelete(false)}>
                        <div className='u-text-center'>
                            <h3>{t('are_you_sure_you_want_delete_log')}</h3>
                            {book.students && book.students.length > 0 ?
                                <Button
                                    className='u-m-right-1 u-m-top-3'
                                    onClick={() => deleteGroupLog()}
                                >
                                    {deletingGroupLog ? `${t('saving')}...` : t('button.yes')}
                                </Button>
                                :
                                <Button
                                    className='u-m-right-1 u-m-top-3'
                                    onClick={() => deleteLog()}>{deletingLog ? `${t('saving')}...` : t('button.yes')}</Button>
                            }
                            <Button className='btn-danger' onClick={() => setConfirmLogDelete(false)}>{t('button.no')}</Button>
                        </div>
                    </Modal>
                }
            </>
        )
    }

    const BookContent = () => {
        const bookContext = useContext(BooksContext)
        const { books, setBooks } = bookContext;

        const [showDelete, setShowDelete] = useState(false);
        const [showDeleteForAll, setShowDeleteForAll] = useState(false);
        const [showDeleteGroupBook, setShowDeleteGroupBook] = useState(false);
        const [deleteError, setDeleteError] = useState(false);

        const [editStudentReadingBook, { loading: editing }] = useMutation(EDIT_STUDENT_READING_BOOK, {
            onCompleted: (data) => {
                if (data.editStudentReadingBook.errors && data.editStudentReadingBook.errors.length > 0) {
                    setEditErrors(true);
                    return
                }
                if (data.editStudentReadingBook && data.editStudentReadingBook.studentReadingBook && data.editStudentReadingBook.studentReadingBook.id) {
                    const updatedBook = data.editStudentReadingBook.studentReadingBook
                    const newBooks = []
                    books.forEach(book => {
                        if (book.id === updatedBook.id) {
                            newBooks.push(updatedBook)
                        } else {
                            newBooks.push(book)
                        }
                    });
                    setBooks(newBooks);
                    setEditErrors(false);
                    setShowEditSuccess(true);
                }
            }
        });

        const [editGroupReadingBook, { loading: editingGroupBook }] = useMutation(EDIT_GROUP_BOOK, {
            onCompleted: (data) => {
                if (data.editGroupReadingBook.errors && data.editGroupReadingBook.errors.length > 0) {
                    setEditErrors(true);
                    return;
                }
                if (data.editGroupReadingBook && data.editGroupReadingBook.groupReadingBook && data.editGroupReadingBook.groupReadingBook.id) {
                    const updatedBook = data.editGroupReadingBook.groupReadingBook
                    const newBooks = []
                    books.forEach(book => {
                        if (book.id === updatedBook.id) {
                            newBooks.push(updatedBook)
                        } else {
                            newBooks.push(book)
                        }
                    });
                    setBooks(newBooks);
                    setEditErrors(false);
                    setShowEditSuccess(true);
                }
            }
        })


        const [assignReadingBook, { loading: assigningBook }] = useMutation(ASSIGN_READING_BOOK, {
            onCompleted: (data) => {
                if (data.assignReadingBookToStudent.errors && data.assignReadingBookToStudent.errors.length > 0) {
                    setAssignBookErrors(true);
                    return
                }
                if (data.assignReadingBookToStudent && data.assignReadingBookToStudent.studentReadingBook && data.assignReadingBookToStudent.studentReadingBook.id) {
                    const newBooks = books;
                    newBooks.unshift(data.assignReadingBookToStudent.studentReadingBook);
                    setBooks(newBooks);
                    setSelectedBook({ open: true, book: data.assignReadingBookToStudent.studentReadingBook });
                    setAssignBookErrors(false);
                }
            }

        });

        const [deleteBook] = useMutation(DELETE_READING_BOOK_MUTATION, {
            awaitRefetchQueries: true,
            refetchQueries: [{ query: GET_STUDENT_QUERY, variables: { id: student.id } }],
            variables: {
                readingBookId: selectedBook.book && selectedBook.book.book.id,
                studentId: student.id
            },
            onCompleted: (data) => {
                if (data.deleteReadingBookFromStudent.deleted) {
                    const newBooks = []
                    books.forEach(book => {
                        if (book.id !== selectedBook.book.id) {
                            newBooks.push(book)
                            return;
                        }
                        return
                    })
                    closeBook()
                    setBooks(newBooks)
                    setShowDelete(false)
                } else {
                    setDeleteError(true)
                }
            }
        })

        const [deleteGroupBook] = useMutation(DELETE_GROUP_BOOK, {
            awaitRefetchQueries: true,
            refetchQueries: [{ query: GET_STUDENT_QUERY, variables: { id: student.id } }],
            variables: {
                groupReadingBookId: selectedBook.book && selectedBook.book.id,
                studentId: student.id
            },
            onCompleted: (data) => {
                if (data.deleteGroupReadingBook.deleted) {
                    const newBooks = []
                    books.forEach(book => {
                        if (book.id !== selectedBook.book.id) {
                            newBooks.push(book)
                            return;
                        }
                        return
                    })
                    closeBook()
                    setBooks(newBooks)
                    setShowDeleteGroupBook(false)
                } else {
                    setDeleteError(true)
                }
            }
        })

        const [deleteBookFromClass] = useMutation(DELETE_READING_BOOK_FROM_CLASS, {
            awaitRefetchQueries: true,
            refetchQueries: [{ query: GET_STUDENT_QUERY, variables: { id: student.id } }],
            variables: {
                readingBookId: selectedBook.book && selectedBook.book.book.id,
                studentsClassId: classInfo && classInfo.id
            },
            onCompleted: (data) => {
                if (data.deleteReadingBookFromClass.deleted) {
                    const newBooks = []
                    books.forEach(book => {
                        if (book.id !== selectedBook.book.id) {
                            newBooks.push(book)
                            return;
                        }
                        return
                    })
                    closeBook();
                    setBooks(newBooks);
                    setShowDeleteForAll(false);
                } else {
                    setDeleteError(true);
                }
            }
        })


        const book = _.filter(books, book => {
            return book.id === selectedBook.book.id;
        })[0];

        const [actionFilter, setActionFilter] = useState(showEditSuccess ? 'BOOKINFO' : ((book && book.status === RB_COMPLETE) || showLogSuccess || showLogDeleteSuccess || selectedBook.history) ? "HISTORY" : "LOG");
        const [recentLogs, setRecentLogs] = useState([])
        const [selectedLog, setLog] = useState(null);

        const [editErrors, setEditErrors] = useState(false);
        const [assignBookErrors, setAssignBookErrors] = useState(false);
        const [reopenErrrors, setReopenErrors] = useState([]);

        const [editBook, setEditBook] = useState({
            bookType: book.bookType,
            commentsEnabled: !book.hideComments
        });

        useEffect(() => {
            if (book && book.status === RB_COMPLETE) {
                setActionFilter('HISTORY');
            }
        }, [book])

        let endDate = moment(book.completedAt);

        const [logFilters, setLogFilters] = useState(['Teacher', 'Parent', 'Student']);

        const [uncompleteStudentReadingBook, { loading: uncompleting }] = useMutation(UNCOMPLETE_STUDENT_READING_BOOK, {
            onCompleted: (data) => {
                if (data.uncompleteStudentReadingBook.errors && data.uncompleteStudentReadingBook.errors.length > 0) {
                    setReopenErrors(data.uncompleteStudentReadingBook.errors)
                    return
                }
                if (data.uncompleteStudentReadingBook && data.uncompleteStudentReadingBook.studentReadingBook && data.uncompleteStudentReadingBook.studentReadingBook.id) {
                    const updatedBook = data.uncompleteStudentReadingBook.studentReadingBook
                    const newBooks = []
                    books.forEach(book => {
                        if (book.id === updatedBook.id) {
                            newBooks.push(updatedBook)
                        } else {
                            newBooks.push(book)
                        }
                    });
                    setBooks(newBooks);
                    setShowSuccess(false);
                    setReopenErrors([]);
                }
            }
        });

        const onClickEdit = (log) => {
            setActionFilter("EDIT");
            setLog(log);
        }

        const handleFilter = filter => {
            if (logFilters.indexOf(filter) !== -1) {
                const newLogs = logFilters.filter(f => f !== filter);
                setLogFilters(newLogs);
            } else {
                const newL = [...logFilters, filter];
                setLogFilters(newL);
            }
        }

        const [fetchLogs, { data: logData, loading }] = useLazyQuery(GET_SINGLE_BOOK, {
            onCompleted: data => {
                if (data && data.studentBook && data.studentBook.logs) {
                    setRecentLogs(_.reverse(_.sortBy(data.studentBook.logs, ['date'])))
                }
            },
            fetchPolicy: 'network-only'
        });

        const [fetchGroupLogs, { data: groupLogData }] = useLazyQuery(GET_GROUP_BOOK, {
            onCompleted: data => {
                if (data && data.groupBook && data.groupBook.logs) {
                    setRecentLogs(_.reverse(_.sortBy(data.groupBook.logs, ['date'])))
                }
            },
            fetchPolicy: 'network-only'
        });

        useEffect(() => {
            if (groupLogData && groupLogData.groupBook && groupLogData.groupBook.logs) {
                setRecentLogs(_.reverse(_.sortBy(groupLogData.groupBook.logs, ['date'])))
            }
        }, [groupLogData]);

        useEffect(() => {
            if (logData && logData.studentBook && logData.studentBook.logs.length > 0) {
                setRecentLogs(_.reverse(_.sortBy(logData.studentBook.logs, ['date'])))
            }
        }, [logData]);

        useEffect(() => {
            if (actionFilter === "HISTORY") {
                if (!book.bookType) {
                    fetchGroupLogs({ variables: { id: book.id } })
                } else {
                    fetchLogs({ variables: { id: book.id } })
                }
            }
        }, [actionFilter])


        return (
            <>
                <h1 className={cx(styles.bookHeading, "u-m-base-5")}>{book.book.title}</h1>
                <TypeLabel type={book.bookType ? book.bookType : 'NewGroup'} />
                {book.bookType && book.bookType === READING_BOOK_TYPE.group &&
                    <div className={cx(styles.groupAlert, styles.groupAlertLegacy, "u-m-top-2")}><Icon.AlertCircle stroke="#C53464" style={{ marginRight: '10px' }} /> <p>This is a legacy group book but you can continue to add reading logs to individual pupils and complete this book. Please note that this book will not appear in the <span className="u-bold">new</span> ‘book groups’ tab.</p></div>
                }
                {book.status === RB_COMPLETE && (
                    <>
                        <span className={styles.completeLabel}><Icon.CheckCircle size="18" className={styles.completeIcon} /> {t('completed')}</span>
                        {book.bookType !== READING_BOOK_TYPE.group && book.bookType && (
                            <>
                                <button className={cx(styles.btnReread, "u-m-left-2 btn-reset")} onClick={() => assignReadingBook({
                                    variables: {
                                        bookId: book.book.id,
                                        studentId: student.id,
                                        bookType: book.bookType,
                                        isReread: true,
                                        hideComments: book.hideComments !== null ? book.hideComments : false
                                    }
                                })}>{assigningBook ? t('saving') : <><Icon.PlusCircle size="20" /> {t('re_read_book')}</>}</button>
                                {assignBookErrors && <p className="error u-smaller u-m-base-10">{t('oops_something_went_wrong_try_again')}</p>}
                                <button className={cx(styles.btnReread, "u-m-left-2 btn-reset")} onClick={() => uncompleteStudentReadingBook({
                                    variables: {
                                        studentReadingBookId: book.id,
                                    }
                                })}>{uncompleting ? t('saving') : <><Icon.PlusCircle size="20" /> Reopen book</>}</button>
                                {reopenErrrors && <FormErrors errors={reopenErrrors} />}
                            </>
                        )}
                    </>
                )}
                {(actionFilter !== 'EDIT' && actionFilter !== 'LOGAFTER') &&
                    <div className={cx(styles.bookFilters, styles.bookFiltersLog, "u-m-top-20 u-m-base-20")}>
                        {book.status !== RB_COMPLETE && <button className={styles.filter} onClick={() => { setActionFilter('LOG'); setShowEditSuccess(false) }}><span className={cx(styles.filterInner, actionFilter === 'LOG' ? styles.filterInnerActive : null)}>{t('log_reading')}</span></button>}
                        <button className={styles.filter} onClick={() => setActionFilter('HISTORY')}><span className={cx(styles.filterInner, actionFilter === 'HISTORY' ? styles.filterInnerActive : null)}>{t('history')}</span></button>
                        <button className={styles.filter} onClick={() => setActionFilter('BOOKINFO')}><span className={cx(styles.filterInner, actionFilter === 'BOOKINFO' ? styles.filterInnerActive : null)}>{t('settings')}</span></button>

                        {(book.status === RB_COMPLETE) &&
                            <Dropdown classNames={styles.bookFiltersLogAction}>
                                {book.status === RB_COMPLETE &&
                                    <li><button onClick={() => setActionFilter("LOGAFTER")} className={styles.bookActionLog}><Icon.PlusCircle className={styles.bookActionLogIcon} />{t('add_log')}</button></li>
                                }
                            </Dropdown>
                        }
                    </div>
                }
                {actionFilter === 'LOG' &&
                    <>
                        {showSuccess ?
                            <>
                                <h1 className={styles.bookHeading}>{t('thanks')}</h1>
                                <p className="u-m-base-20">{t('your_log_for_name_added', { studentName: student.firstName })}</p>
                                <Button onClick={() => setShowSuccess(false)}>{t('log_more')}</Button>
                            </> :
                            <>
                                {book.students && book.students.length > 0 &&
                                    <div className={styles.groupAlert}><Icon.AlertCircle stroke="#C53464" style={{ marginRight: '10px' }} /> <p>Add a log to this pupil only.</p></div>
                                }
                                <LogNew initiateComplete={initiateComplete} initiateMethod={setInitiateComplete} toggleComplete={setCompleteConfirmation} book={book} student={student} recentLog={book.mostRecentLog && book.mostRecentLog.pageEnd} close={setSelectedBook} success={setShowSuccess} singleGroupLog={book.students && book.students.length > 0} />
                            </>
                        }
                    </>
                }
                {actionFilter === 'LOGAFTER' &&
                    <>
                        <div className="u-m-top-20">
                            <LogNew book={book} student={student} recentLog={book.mostRecentLog && book.mostRecentLog.pageEnd} close={setActionFilter} success={setShowCompleteLogSuccess} complete={true} />
                        </div>
                    </>
                }
                {actionFilter === 'HISTORY' &&
                    <>
                        {showCompleteLogSuccess ?
                            <>
                                <h1 className={styles.bookHeading}>{t('thanks')}</h1>
                                <p className="u-m-base-20">{t('your_log_for_name_added', { studentName: student.firstName })}</p>
                                <Button onClick={() => { setShowCompleteLogSuccess(false); setActionFilter("HISTORY") }}>{t('thanks')}</Button>
                            </> : <>
                                {book.createdAt && <p className="u-m-base-10">{t('book_assigned')}: {moment(book.createdAt).format("DD MMM YY")}</p>}

                                {book.status === RB_COMPLETE && <>
                                    <p className="u-m-base-20">{t('completed')}: {endDate.format("DD MMM YY")}</p>

                                    {(moment(new Date()).diff(moment(endDate), 'days') < MAX_DAYS_AGO) && (
                                        <p className='u-m-base-20'>{t('book_stays_in_current_listing_7_days')}</p>
                                    )}

                                </>
                                }
                                {showLogDeleteSuccess &&
                                    <p className={cx(styles.editMeta, "u-bold u-m-base-20")}><Icon.CheckCircle className="u-m-right-10" color="#6B9A11" />{t('log_delete_success')}</p>
                                }

                                {book.status === RB_COMPLETE &&
                                    <StudentBookReview teacher={teacher} classId={classId || (classInfo && classInfo.id)} book={book} student={student} />
                                }
                                {loading && <img src={loader} alt="Loading" />}
                                {
                                    recentLogs.length !== 0 ? (
                                        <>
                                            <ul className={styles.logListFilters}>
                                                <li>
                                                    <p onClick={() => handleFilter('Teacher')}>{logFilters.indexOf('Teacher') !== -1 ? <Icon.CheckSquare className='u-text-primary' /> : <Icon.Square />} {t('teachers')}</p>
                                                </li>
                                                <li>
                                                    <p onClick={() => handleFilter('Parent')}>{logFilters.indexOf('Parent') !== -1 ? <Icon.CheckSquare className='u-text-primary' /> : <Icon.Square />} {t('parents')}</p>
                                                </li>
                                                <li>
                                                    <p onClick={() => handleFilter('Student')}>{logFilters.indexOf('Student') !== -1 ? <Icon.CheckSquare className='u-text-primary' /> : <Icon.Square />} {t('pupil')}</p>
                                                </li>
                                            </ul>
                                            {showLogSuccess &&
                                                <p className={cx(styles.editMeta, "u-bold u-m-base-10 u-m-top-10")}><Icon.CheckCircle className="u-m-right-10" color="#6B9A11" />{t('log_successfully_updated')}</p>
                                            }
                                            <ul className={styles.logList}>
                                                {recentLogs.filter(l => logFilters.includes(l.author.role)).map(log => {
                                                    return <Log key={`activeBook${log.id}`} log={log} book={book} teacher={teacher} action={onClickEdit} />
                                                })}
                                            </ul>
                                        </>
                                    ) : <h4>{t('no_logs_added_to_book')}</h4>
                                }
                            </>}
                    </>

                }
                {actionFilter === 'BOOKINFO' &&
                    <>
                        <h2 className={cx(styles.bookHeading, "u-m-base-5")}>{book.book.title}</h2>
                        {book.book.author && <p>{book.book.author}</p>}
                        {book.book.isbn && <p className="u-m-top-20">ISBN: {book.book.isbn}</p>}
                        {book.book.isbn13 && <p>ISBN13: {book.book.isbn13}</p>}
                        {book.book.publisher && <p className="u-m-top-20">{book.book.publisher.name}</p>}
                        {book.bookType &&
                            <div className={styles.editBookType}>
                                <label>{t('book_type')}:</label>
                                {
                                    book.bookType === READING_BOOK_TYPE.group ? <p className='u-display-flex u-align-center'><b className='u-m-right-10'>Group</b> <Icon.Lock className='u-m-left-10' /></p> :
                                        <select value={editBook.bookType} className='basic-form__text-select' onChange={(e) => setEditBook({ ...editBook, bookType: parseInt(e.target.value) })}>
                                            {BOOK_TYPES.filter(t => t.type !== READING_BOOK_TYPE.group).map(type =>
                                                <option key={`type${type.type}`} value={type.type}>{t(type.label)}</option>
                                            )}
                                        </select>
                                }
                            </div>
                        }
                        <div>
                            <div className='u-m-top-20 u-m-base-20 u-display-flex'>
                                <p className='u-m-right-10'>{t('pupil_comments_enabled')}</p>
                                <input type="checkbox" className={styles.switchToggle} id="switchc" checked={editBook.commentsEnabled} onChange={(e) => setEditBook({ ...editBook, commentsEnabled: !editBook.commentsEnabled })} value={editBook.commentsEnabled} />
                                <label className={styles.switchLabel} htmlFor="switchc">{t('toggle')}</label>
                            </div>
                        </div>

                        {editErrors && <p className="error u-smaller u-m-base-10">{t('oops_something_went_wrong_try_again')}</p>}

                        {showEditSuccess && <p className='u-bold u-m-base-2'>Changes saved!</p>}
                        {book.students && book.students.length > 0 ?
                            <Button disabled={editingGroupBook}
                                onClick={
                                    () => editGroupReadingBook({
                                        variables: {
                                            groupReadingBookId: book.id,
                                            hideComments: editBook.commentsEnabled ? false : true,
                                        }
                                    })
                                }
                            >
                                {editingGroupBook ? t('saving') : t('button.save_changes')}
                            </Button>
                            :
                            <Button disabled={editing} onClick={() => editStudentReadingBook({
                                variables: {
                                    studentReadingBookId: book.id,
                                    hideComments: editBook.commentsEnabled ? false : true,
                                    bookType: editBook.bookType
                                }
                            })}>{editing ? t('saving') : t('button.save_changes')}</Button>
                        }
                        {book.students && book.students.length > 0 ?
                            <div className={styles.deleteBookPanel}>
                                <div className={styles.deleteBookPanel}>
                                    <h2 className={cx(styles.deleteHeader, 'u-m-base-10')}>Delete Book?</h2>
                                    <p className="u-m-top-10 u-m-base-10">You can delete this book if you are not planning on using it.</p>
                                    <Button className='btn-danger' onClick={() => setShowDeleteGroupBook(true)}>{t('button.delete')}</Button>
                                </div>
                            </div>
                            :
                            <div className={styles.deleteBookPanel}>
                                <h2 className={cx(styles.deleteHeader, 'u-m-base-10')}>Delete Book?</h2>
                                <p className="u-m-top-10 u-m-base-10">You can delete this book if you are not planning on using it.</p>
                                <Button className='btn-danger' onClick={() => setShowDelete(true)}>{t('button.delete')}</Button>
                            </div>
                        }
                        {
                            classInfo && classInfo.id && book.bookType === READING_BOOK_TYPE.group && (
                                <div className={styles.deleteBookPanel}>
                                    <h2 className={cx(styles.deleteHeader, 'u-m-base-10')}>Delete for all?</h2>
                                    <p className="u-m-top-10 u-m-base-10">Permanently remove this group book and any associated reading logs from this pupil’s record.</p>
                                    <Button className='btn-danger' onClick={() => setShowDeleteForAll(true)}>Delete for all</Button>
                                </div>
                            )
                        }




                    </>
                }
                {actionFilter === 'EDIT' &&
                    <EditLog book={book} log={selectedLog} setSearch={setSearch} close={() => { setActionFilter("HISTORY"); setLog(null); }} />
                }
                {showDelete &&
                    <Modal closeModal={() => { setShowDelete(false); setDeleteError(false) }}>
                        <p>Are you sure? Deleting this book will permanently remove it from the log</p>
                        {deleteError && <p className="u-m-top-10 error">Sorry, something went and wrong and the book wasn't deleted.</p>}
                        <Button onClick={() => deleteBook()} className="u-m-top-20 u-m-right-10">Yes</Button>
                        <Button outline onClick={() => { setShowDelete(false); setDeleteError(false) }}>No</Button>
                    </Modal>
                }

                {showDeleteForAll &&
                    <Modal closeModal={() => { setShowDeleteForAll(false); setDeleteError(false) }}>
                        <p>Are you sure? Deleting this book will permanently remove it from the log</p>
                        {deleteError && <p className="u-m-top-10 error">Sorry, something went and wrong and the book wasn't deleted.</p>}
                        <Button onClick={() => deleteBookFromClass()} className="u-m-top-20 u-m-right-10">Yes</Button>
                        <Button outline onClick={() => { setShowDeleteForAll(false); setDeleteError(false) }}>No</Button>
                    </Modal>
                }

                {showDeleteGroupBook &&
                    <Modal closeModal={() => { setShowDeleteGroupBook(false); setDeleteError(false) }}>
                        <p>Are you sure? Deleting this book will permanently remove it from the log</p>
                        {deleteError && <p className="u-m-top-10 error">Sorry, something went and wrong and the book wasn't deleted.</p>}
                        <Button onClick={() => deleteGroupBook()} className="u-m-top-20 u-m-right-10">Yes</Button>
                        <Button outline onClick={() => { setShowDeleteGroupBook(false); setDeleteError(false) }}>No</Button>
                    </Modal>
                }

            </>
        )
    }


    // utils for book sorting tabs
    const MAX_DAYS_AGO = 7;

    const logOptions = [
        { text: t('All Books'), type: 'all', action: () => { setLogOption('all'); setLogSearch(true) }, styleName: cx({ 'u-text-primary': logOption === 'all' }) },
        { text: t('Reading Book'), type: 1, action: () => { setLogOption(1); setLogSearch(true) }, styleName: cx({ 'u-text-primary': logOption === 1 }) },
        { text: t('Library Book'), type: 2, action: () => { setLogOption(2); setLogSearch(true) }, styleName: cx({ 'u-text-primary': logOption === 2 }) },
        { text: t('Home Book'), type: 3, action: () => { setLogOption(3); setLogSearch(true) }, styleName: cx({ 'u-text-primary': logOption === 3 }) },
    ]

    const typeOptions = [
        { text: t('All Books'), type: 'all', action: () => { setSortType('all'); setSearch(true) }, styleName: cx({ 'u-text-primary': sortType === 'all' }) },
        { text: t('Reading Book'), type: 1, action: () => { setSortType(1); setSearch(true) }, styleName: cx({ 'u-text-primary': sortType === 1 }) },
        { text: t('Library Book'), type: 2, action: () => { setSortType(2); setSearch(true) }, styleName: cx({ 'u-text-primary': sortType === 2 }) },
        { text: t('Home Book'), type: 3, action: () => { setSortType(3); setSearch(true) }, styleName: cx({ 'u-text-primary': sortType === 3 }) },
        { text: t('Group Book'), type: 4, action: () => { setSortType(4); setSearch(true) }, styleName: cx({ 'u-text-primary': sortType === 4 }) },
    ]

    const typeTitle = (typeOptions.find(o => o.type === sortType) && typeOptions.find(o => o.type === sortType).text) || '';

    const logTitle = (logOptions.find(o => o.type === logOption) && logOptions.find(o => o.type === logOption).text) || '';

    const statusOptions = [
        { text: t('active'), type: 'active', action: () => { setFilterType('active'); setSearch(true) }, styleName: cx({ 'u-text-primary': filterType === 'active' }) },
        { text: t('complete'), type: 'complete', action: () => { setFilterType('complete'); setSearch(true) }, styleName: cx({ 'u-text-primary': filterType === 'complete' }) },
    ]

    const statusTitle = (statusOptions.find(o => o.type === filterType) && statusOptions.find(o => o.type === filterType).text) || '';

    const handleSubmit = (e) => {
        e.preventDefault();
        setSearch(true);
    }

    return (
        <>
            {
                parse(location.search).printCode && (
                    <PrintableCode student={studentState} />
                )
            }
            <div className={styles.studentContent}>
                <div className={styles.header}>
                    {classId ?
                        <Link to={`/class/${classId}`} state={{ backToClass: false }} className={styles.studentBack}><Icon.ArrowLeft size="26" className={styles.studentBack} /></Link>
                        :
                        classInfo && classInfo.id ?
                            <Link to={`/class/${classInfo.id}`} state={{ backToClass: true }} className={styles.studentBack}><Icon.ArrowLeft size="26" className={styles.studentBack} /></Link> :
                            <Icon.ArrowLeft size="26" onClick={() => window.history.back()} className={styles.studentBack} />
                    }
                    <img src={studentState.boomerAvatarUrl ? `${studentState.boomerAvatarUrl}?v=${studentState.updatedAt.replace(/\s/g, '')}` : boomer} className={styles.studentAva} />
                    <h1 className={styles.studentName}>{studentState.name}
                        {student.school.readingBands.length > 0 &&
                            <span className='u-m-top-1' onClick={() => setShowBand({ ...showBand, show: true })}>
                                {student.currentReadingRank ?
                                    <span className={cx(styles.bandLabel)}><span style={{ background: student.currentReadingRank.readingBandRank.colourCode }} className={styles.bandPill}></span>{student.currentReadingRank.readingBandRank.name}</span> :
                                    <span className={styles.bandLabel}>{t('no_reading_band')}</span>
                                }
                            </span>
                        }
                        {student.yearGroup && <span className={styles.ygLabel}>{getYearGroupName(student.yearGroup)}</span>}
                        <SetFlag student={student} />
                    </h1>
                    <button className={cx(styles.infoBtn, classInfo && classInfo.id && styles.infoBtnClass)} onClick={() => setShowInfo(true)}><Icon.Info /></button>
                </div>
                {showBookForm &&
                    <BookSearch studentId={student.id} close={setShowBookForm} group={classInfo && classInfo} reset={resetSearch} />
                }
                <div className={styles.searchBar} style={{ 'display': showBookForm ? 'none' : 'flex' }}>
                    <Tooltip title="Book List" size="big"><button className={cx(styles.searchBarFilter, section === 'books' && styles.searchBarFilterActive)} onClick={() => setSection('books')}><Icon.Book />Books</button></Tooltip>
                    <Tooltip title="Activity Feed" size="big"><button className={cx(styles.searchBarFilter, section === 'logs' && styles.searchBarFilterActive)} onClick={() => { setSection('logs'); setInitiateActivity(true) }}><Icon.Activity />Activity</button></Tooltip>
                    {userPermissions && userPermissions.canDownloadStudentData && <Tooltip title="Data Downloads" size="big"><button className={cx(styles.searchBarFilter, section === 'downloads' && styles.searchBarFilterActive)} onClick={() => { setSection('downloads'); }}><Icon.TrendingUp />Data</button></Tooltip>}
                    {userPermissions && <Tooltip title={userPermissions.canManageStudentNotes ? 'Pupil Notes' : 'Talk to your admin for access'} size="big"><button disabled={!userPermissions.canManageStudentNotes} className={cx(styles.searchBarFilter, section === 'notes' && styles.searchBarFilterActive)} onClick={() => { setSection('notes'); }}><Icon.Clipboard />Notes</button></Tooltip>}

                    {section === 'books' &&
                        <>
                            <Dropdown dropdownOpts={typeOptions} classNames={cx(classStyles.classActionsButton, 'u-m-left-2 u-m-right-2')} leftAligned titleLeft={`${typeTitle}`} />
                            <Dropdown dropdownOpts={statusOptions} classNames={cx(classStyles.classActionsButton)} leftAligned titleLeft={`${statusTitle}`} />
                            <form onSubmit={(e) => handleSubmit(e)} className={styles.readingBookSearch}>
                                <input
                                    type="text"
                                    className={cx('basic-form__text-box', classStyles.classActionsSearch, classStyles.classActionsSearchSplit)}
                                    value={searchTerm}
                                    placeholder={t('search_books')}
                                    onChange={e => setSearchTerm(e.target.value)}
                                />
                                <button type="submit" className={styles.searchBtn}><Icon.Search size="16" /></button>
                            </form>
                        </>}
                </div>
                <div className={styles.main} style={{ 'display': showBookForm ? 'none' : 'flex' }}>
                    <div className={styles.books} style={{
                        display: section === 'books' ? 'block' : 'none'
                    }}>
                        <Search student={student} term={searchTerm} type={sortType} status={filterType} search={search} searchMethod={setSearch} event={setSelectedBook} />
                    </div>
                    <div className={styles.books} style={{
                        display: section === 'logs' ? 'block' : 'none'
                    }}>
                        <Activity classId={classId || (classInfo && classInfo.id)} setSection={setSection} userPermissions={userPermissions} downloadData={() => { setSection('downloads'); setShowDataForm(true) }} student={student} type={logOption} search={logSearch} searchMethod={setLogSearch} initiate={initiateActivity} />
                    </div>
                    {section === 'downloads' && (
                        <div className={styles.books}>
                            <DataDownload userPermissions={userPermissions} showDataForm={showDataForm} setShowDataForm={setShowDataForm} backToStudent={() => setSection('books')} student={student} teacher={teacher} />
                        </div>
                    )}
                    {section === 'notes' && (
                        <div className={styles.books}>
                            <Notes student={student} />
                        </div>
                    )}

                    {section === 'books' &&
                        <div className={styles.aside}>
                            <div className={styles.asideActions}>
                                <Button className={cx(styles.iconBtn, "u-m-right-2 u-m-base-2 btn-small")} onClick={() => setShowBookForm(true)}><Icon.PlusCircle className="u-m-right-5" /> {t('book')}</Button>
                                {userPermissions && (
                                    <Tooltip disabled={userPermissions.canManageStudentNotes} title="Talk to your admin for access" size="big">
                                        <Button disabled={!userPermissions.canManageStudentNotes} className={cx(styles.iconBtn, 'u-m-base-2 u-m-right-2 btn-small')} onClick={() => setShowNoteForm(true)}><Icon.PlusCircle className="u-m-right-5" /> {t('note')}</Button>
                                    </Tooltip>
                                )}
                                <GemButton studentId={student.id} plus={true} />
                            </div>
                            {completedSurveys.map(stSurvey =>
                                <div key={stSurvey.id} className={'card u-m-top-2 u-m-base-3'}>
                                    <div className="card-body u-display-flex u-align-center">
                                        <img src={survey} width={50} />
                                        <p className='u-m-left-2 bold heavy'><b>{stSurvey.survey?.title} Completed</b></p>
                                    </div>
                                </div>
                            )}

                            <div className="u-m-top-2">
                                <div className={styles.recentLogHeader}>
                                    <p className={styles.recentLogHeaderMain}>Last read:</p>
                                    <p className={styles.recentLogHeaderWeek}>This week</p>
                                    <p className={styles.recentLogHeaderWeek}>This year</p>
                                </div>
                                <div className={cx(styles.recentLog, styles.recentLogTop)}>
                                    <img src={teacherIcon} alt="" className={styles.recentLogImg} />
                                    <div className={styles.recentLogMain}>
                                        {student.stats.lastTeacherLog ? (
                                            <>
                                                <p className={styles.recentDateAgo}>{timeAgo(student.stats.lastTeacherLog)}</p>
                                                <span className={styles.recentDate}>{moment(student.stats.lastTeacherLog, "YYYY-MM-DD").format('DD MMM YY')}</span>
                                            </>
                                        ) : '--'}
                                    </div>
                                    <div className={styles.recentWeekly}>
                                        <span className={styles.recentDateNo}>{student.stats.teacherLogs}</span>
                                    </div>
                                    <div className={styles.recentWeekly}>
                                        <span className={styles.recentDateNo}>{student.stats.currentYearTeacherLogsCounter}</span>
                                    </div>
                                </div>
                                <div className={cx(styles.recentLog, styles.recentLogMiddle)}>
                                    <span className={styles.linkedParents}>
                                        {student.parentsCounter === 0 ? <Icon.AlertCircle className={cx(styles.recentLogImg, 'u-text-accent-2')} /> :
                                            <>
                                                <img src={homeIcon} alt="" className={styles.recentLogImg} />
                                                <span className={styles.linkedParentsCount}>{student.parentsCounter}</span>
                                            </>
                                        }
                                    </span>
                                    <div className={styles.recentLogMain}>
                                        {student.parentsCounter === 0 ? <p className={styles.recentDateAgo}>No linked parents</p> : student.stats.lastParentLog ? (
                                            <>
                                                <p className={styles.recentDateAgo}>{timeAgo(student.stats.lastParentLog)}</p>
                                                <span className={styles.recentDate}>{moment(student.stats.lastParentLog, "YYYY-MM-DD").format('DD MMM YY')}</span>
                                            </>
                                        ) : '--'}
                                    </div>
                                    {student.parentsCounter > 0 &&
                                        <>
                                            <div className={styles.recentWeekly}>
                                                <span className={styles.recentDateNo}>{student.stats.parentLogs}</span>
                                            </div>
                                            <div className={styles.recentWeekly}>
                                                <span className={styles.recentDateNo}>{student.stats.currentYearParentLogsCounter}</span>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className={cx(styles.recentLog, styles.recentLogMiddle)}>
                                    <img src={studentState.boomerAvatarUrl ? `${studentState.boomerAvatarUrl}?v=${studentState.updatedAt.replace(/\s/g, '')}` : boomer} className={styles.recentStudentAva} />
                                    <div className={styles.recentLogMain}>
                                        {student.stats.lastStudentLog ? (
                                            <>
                                                <p className={styles.recentDateAgo}>{timeAgo(student.stats.lastStudentLog)}</p>
                                                <span className={styles.recentDate}>{moment(student.stats.lastStudentLog, "YYYY-MM-DD").format('DD MMM YY')}</span>
                                            </>
                                        ) : '--'}
                                    </div>
                                    <div className={styles.recentWeekly}>
                                        <span className={styles.recentDateNo}>{student.stats.studentLogs}</span>
                                    </div>
                                    <div className={styles.recentWeekly}>
                                        <span className={styles.recentDateNo}>{student.stats.currentYearStudentLogsCounter}</span>
                                    </div>
                                </div>
                            </div>



                            <p className="u-m-top-20 u-m-base-10 u-muli">{t('recent_problem_words')}:</p>
                            <ul className={styles.recentWordList}>
                                {studentState.mostRecentProblemWords.length > 0 ?
                                    studentState.mostRecentProblemWords.map(pword => {
                                        return (
                                            <li key={`problemWord${pword.id}`} className={styles.recentWord}>{pword.word} <span className={styles.recentWordTime}>{pword.timestamp}</span></li>
                                        )
                                    }) : (<p>NA</p>)}
                                {studentState.problemWordsCounter > 0 && (
                                    <button className='btn-reset u-text-primary u-m-left-2' onClick={() => setShowProblemWords(true)}>{t('view_all')}</button>
                                )}
                            </ul>

                            <div className="u-m-top-2">
                                <div className={styles.recentLogHeader}>
                                    <p className={styles.recentLogHeaderMain}>Books read:</p>
                                    <p className={styles.recentLogHeaderWeek}>This week</p>
                                    <p className={styles.recentLogHeaderWeek}>This year</p>
                                </div>
                                <div className={cx(styles.recentLog, styles.recentLogBottom)}>
                                    <img src={bookIcon} alt="" className={styles.recentLogImg} />
                                    <div className={styles.recentLogMain}>
                                        <span className={styles.recentDateAgo}>{student.stats.totalBooksCounter}</span>
                                    </div>
                                    <div className={styles.recentWeekly}>
                                        <span className={styles.recentDateNo}>{student.stats.currentWeekBooksCounter}</span>
                                    </div>
                                    <div className={styles.recentWeekly}>
                                        <span className={styles.recentDateNo}>{student.stats.currentYearBooksCounter}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {selectedBook.open && <div onClick={() => closeBook()} className={styles.bookBG}></div>}
                <div className={cx(styles.bookModal, selectedBook.open && styles.bookModalOpen)}>
                    {selectedBook.book &&
                        <BookContent />
                    }
                </div>
                {selectedBook.open && <Icon.XCircle onClick={() => closeBook()} className={styles.closeBook} />}

                {showNoteForm &&
                    <SideModal closeModal={() => setShowNoteForm(false)}>
                        <AddNote student={student} close={() => setShowNoteForm(false)} feedbackInModal={true} />
                    </SideModal>
                }

                {showBand.show &&
                    <AssignBand close={setShowBand} student={student} />
                }

                {showProblemWords && (
                    <Modal wide closeModal={() => setShowProblemWords(false)}>
                        <AllProblemWords studentState={studentState} />
                    </Modal>
                )}


                {completeConfirmation &&
                    <Modal closeModal={() => { setCompleteConfirmation(false) }}>
                        <p>Are you sure you want to mark this book as complete?</p>
                        <Button onClick={() => { setInitiateComplete(true); setCompleteConfirmation(false) }} className="u-m-top-20 u-m-right-10">Yes</Button>
                        <Button outline onClick={() => { setCompleteConfirmation(false) }}>No</Button>
                    </Modal>
                }
                {showInfo &&
                    <Modal closeModal={() => { setShowInfo(false); setShowRefreshLoginCode(false) }}>
                        {showRefreshLoginCode ?
                            <LoginCode
                                student={studentState}
                                setStudentState={setStudentState}
                                closeModal={() => setShowRefreshLoginCode(false)}
                            />
                            :
                            <>
                                <div className={styles.infoHeader}>
                                    <img src={studentState.boomerAvatarUrl ? `${studentState.boomerAvatarUrl}?v=${studentState.updatedAt.replace(/\s/g, '')}` : boomer} className={styles.infoStudentAva} />
                                    <h1 className={styles.infoTitle}>{studentState.name}</h1>
                                </div>
                                <p className={cx("altDesc", "u-m-base-10")}>
                                    {t('login_code')}: <span className='u-m-right-10 u-bold'>{studentState.loginCode}</span>
                                    <CopyToClipboard text={studentState.loginCode}
                                        onCopy={() => setCodeCopy('student')}
                                    >
                                        <button className='btn-reset u-text-tertiary u-m-right-20 posRel'>
                                            <Icon.Copy size="16" color="#000" /> {t('copy')}
                                            <span className={cx(styles.copied, codeCopy === 'student' && styles.copiedShow)}>{t('copied')}</span>
                                        </button>
                                    </CopyToClipboard>
                                    <button onClick={() => setShowRefreshLoginCode(true)} className='btn-reset u-text-tertiary'><Icon.RefreshCw size='16' color="#000" /> {t('refresh')}</button>
                                </p>
                                <p className={cx("altDesc", "u-m-base-20")}>
                                    School Code: <span className="u-bold u-m-right-10">{studentState.school.schoolCode}</span>
                                    <CopyToClipboard text={studentState.school.schoolCode}
                                        onCopy={() => setCodeCopy('school')}
                                    >
                                        <button className='btn-reset u-text-tertiary u-m-right-20 posRel'>
                                            <Icon.Copy size="16" color="#000" /> {t('copy')}
                                            <span className={cx(styles.copied, codeCopy === 'school' && styles.copiedShow)}>{t('copied')}</span>
                                        </button>
                                    </CopyToClipboard>
                                </p>
                                <a href={`/student/${studentState.id}?printCode=true`} target='_blank' rel="noopener noreferrer" className='btn-reset u-text-tertiary u-m-top-20'><Icon.Printer size='16' color="#000" /> {t('print_login_sheet')}</a>
                            </>
                        }
                    </Modal>
                }
                {viewClass && <div onClick={() => setViewClass(false)} className={styles.bookBG}></div>}
                <div className={cx(styles.bookModal, styles.bookModalClass, viewClass && styles.bookModalOpen)}>
                    {classInfo && <button className={cx(styles.classTab, viewClass && styles.classTabOpen)} onClick={() => { setViewClass(!viewClass); setClassInitiated(true) }}><Icon.Sidebar /></button>}
                    <div className={styles.classInner}>
                        {classInitiated &&
                            <Class id={classInfo?.id || classId} studentView={true} />
                        }
                    </div>
                </div>
                {viewClass && <Icon.XCircle onClick={() => setViewClass(false)} className={styles.closeBook} />}
            </div>
        </>
    );
}

export default StudentContent;